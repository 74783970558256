<template>
  <div class="ebookDetail">
    <div class="header">
      <div class="headerWrap">
        <router-link class="backBtn" to="/ebook">
          <Icon icon="material-symbols:arrow-back-ios-new-rounded" class="headerIcon" />
        </router-link>
      </div>
    </div>
    <div class="content" v-if="ebookData">
      <div class="contentWrap">
        <div class="topWrap">
          <div class="iconWrap">
            <img class="ebookIcon" :src="ebookData.icon" :alt="ebookData.ebook_name_trans">
            <el-progress class="ebookProgress" :percentage="ebookData.progress" :stroke-width="6" :show-text="false"></el-progress>
          </div>
          <div class="rightWrap">
            <div class="ebookNameTransWrap">
              <div class="ebookNameTrans">{{ebookData.ebook_name_trans}}</div>
              <div v-if="downloadLoading" class="loadingWrap">
                <i class="loadingIcon el-icon-loading"></i>
              </div>
              <div v-else class="downloadWrap" :class="{downloaded: ifDownloaded}" @click="downloadHandle">
                <img class="downloadIcon" src="@/assets/images/ebook/download.png">
                <Icon icon="pajamas:check-circle-filled" v-show="ifDownloaded" class="downloadedIcon"></Icon>
              </div>
            </div>
            <div class="ebookName">{{ebookData.ebook_name}}</div>
            <div class="levelWrap">
              <template v-if="ebookData.level !== 'Pre-A1'">
                <div class="levelName">{{getDifficultyTrans(ebookData.difficulty)}}</div>
                <div class="levelIcon" :class="ebookData.level">
                  <div class="iconBar" v-for="j in 4" :key="j"></div>
                </div>
              </template>
            </div>
            <div class="btnWrap">
              <div class="readBtn" @click="startLearn('read')">
                <img class="btnIcon" src="@/assets/images/ebook/read.png">
                <span class="btnName">{{$t('ebook.start_reading_ebook')}}</span>
              </div>
              <div class="listenBtn" @click="startLearn('listen')">
                <img class="btnIcon" src="@/assets/images/ebook/listen.png">
                <span class="btnName">{{$t('ebook.start_listen_ebook')}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="summaryWrap">{{ebookData.ebook_summary_trans}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vuex from 'vuex'
  import localforage from 'localforage'
  import { Icon } from '@iconify/vue2'
  import { getEbookList, getOneEbook } from '@/api/ebook'
  import '@/assets/styles/ebook/common.scss'

  export default {
    data() {
      return {
        ebookData: null,
        ifDownloaded: false,
        downloadLoading: false
      }
    },
    props: ['ebookID'],
    components: {
      Icon
    },
    filters: {
      difficultyFilter(difficulty) {
        const difficultyMap = ['Easy', 'Normal', 'Normal', 'Hard']
        return difficultyMap[difficulty - 1]
      }
    },
    computed: {
      ...Vuex.mapGetters(['userLanguage', 'learnLanguage'])
    },
    created() {
      this.init()
    },
    methods: {
      async init() {
        let loadingInstance = this.$loading()

        let ebookList = await getEbookList(this.userLanguage, this.learnLanguage)
        let ebookData = ebookList.find(ebook => ebook.id === this.ebookID)
        this.ebookData = ebookData

        // 查看该电子书是否已下载
        let ebookDownloadData = await localforage.getItem('ebookDownloadData')
        let oneEbookData = ebookDownloadData?.[this.learnLanguage]?.[this.ebookID]
        if (oneEbookData) {
          this.ifDownloaded = true
        }

        this.$nextTick(() => {
          loadingInstance.close()
        })
      },
      async downloadHandle() {
        if (!this.ifDownloaded) {
          this.downloadLoading = true
          await getOneEbook(this.learnLanguage, this.ebookID)
          this.ifDownloaded = true
          this.downloadLoading = false
        }
      },
      startLearn(mode) {
        this.$router.push({ name: 'EbookLearn', params: { ebookID: this.ebookID, learnMode: mode } })
      },
      getDifficultyTrans(difficulty) {
        const difficultyMap = ['easy', 'normal', 'normal', 'hard']
        return this.$t(`ebook.${difficultyMap[difficulty - 1]}`)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .ebookDetail {
    background: #EEEEEE;
    min-height: 100vh;
    .header {
      position: sticky;
      left: 0;
      top: 0;
      width: 100vw;
      height: 80px;
      background: #FFFFFF;
      border-bottom: 1px solid #C5C6D2;
      z-index: 1000;
      .headerWrap {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        padding: 0 20px;
        width: 100%;
        max-width: 1000px;
        height: 100%;
        box-sizing: border-box;
        .backBtn {
          display: flex;
          position: absolute;
          left: 20px;
          cursor: pointer;
          .headerIcon {
            width: 32px;
            height: 32px;
            color: #333333;
          }
        }
      }
    }
    .content {
      width: 100vw;
      .contentWrap {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        padding: 40px 20px;
        padding-bottom: calc(10vh + 60px);
        box-sizing: border-box;
        .topWrap {
          display: flex;
          gap: 30px;
          .iconWrap {
            position: relative;
            width: 180px;
            .ebookIcon {
              width: 100%;
            }
            .ebookProgress {
              width: 70%;
              position: absolute;
              bottom: 30px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
          .rightWrap {
            flex: 1;
            position: relative;
            .ebookNameTransWrap {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .ebookNameTrans {
                font-weight: 500;
                font-size: 30px;
                color: #2B3337;
              }
              .loadingWrap {
                .loadingIcon {
                  font-size: 24px;
                  color: #4388FF;
                }
              }
              .downloadWrap {
                position: relative;
                display: flex;
                cursor: pointer;
                &.downloaded {
                  cursor: default;
                }
                .downloadIcon {
                  width: 25px;
                }
                .downloadedIcon {
                  position: absolute;
                  right: -3px;
                  bottom: -3px;
                  width: 12px;
                  height: 12px;
                  border-radius: 12px;
                  color: #67C23A;
                  background: #FFFFFF;
                }
              }
            }
            .ebookName {
              margin-top: 12px;
              font-weight: 500;
              font-size: 20px;
              color: #2B3337;
            }
            .levelWrap {
              display: flex;
              margin-top: 30px;
              align-items: center;
              .levelName {
                font-weight: 500;
                font-size: 14px;
                color: #2B3337;
              }
              .levelIcon {
                margin-left: 10px;
                gap: 4px;
                .iconBar {
                  width: 3px;
                  height: 15px;
                  border-radius: 3px;
                }
              }
            }
            .btnWrap {
              position: absolute;
              left: 0;
              bottom: 20px;
              display: flex;
              align-items: center;
              gap: 50px;
              .readBtn, .listenBtn {
                padding: 0 20px;
                min-width: 220px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                height: 40px;
                line-height: 40px;
                border-radius: 6px;
                font-weight: 500;
                font-size: 14px;
                color: #FFFFFF;
                box-sizing: border-box;
                transition: all 0.1s;
                cursor: pointer;
                .btnIcon {
                  width: 22px;
                }
              }
              .readBtn {
                background: #4B8BFA;
                box-shadow: 0 1px 2px 0 #4b8bfa91;
                &:hover {
                  background: #2D71E7;
                }
              }
              .listenBtn {
                background: #35A5FF;
                box-shadow: 0 1px 2px 0 #4b8bfa80;
                &:hover {
                  background: #2F94E5;
                }
              }
            }
          }
        }
        .summaryWrap {
          margin-top: 20px;
          padding-top: 20px;
          border-top: 1px solid #65717636;
          color: #2b3337;
          font-size: 15px;
          font-weight: 400;
          line-height: 24px;
          white-space: pre-line;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .ebookDetail {
      .content {
        .contentWrap {
          .topWrap {
            flex-direction: column;
            align-items: center;
            .rightWrap {
              width: 100%;
              .ebookNameTransWrap {
                gap: 20px;
                justify-content: center;
              }
              .ebookName {
                text-align: center;
              }
              .levelWrap {
                margin-top: 20px;
                justify-content: center;
              }
              .btnWrap {
                position: unset;
                gap: 20px;
                margin-top: 20px;
                .readBtn, .listenBtn {
                  min-width: unset;
                  flex: 1;
                }
              }
            }
          }
        }
      }
    }
  }
</style>