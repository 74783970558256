<template>
  <div class="ebook">
    <home-header></home-header>
    <div class="contentWrap">
      <div v-if="lastLearnEbook" class="lastLearnWrap">
        <el-image class="ebookIcon" :src="lastLearnEbook.icon" @click="continueRead"></el-image>
        <div class="ebookWrap" @click="continueRead">
          <div class="ebookNameTrans">{{lastLearnEbook.ebook_name_trans}}</div>
          <div class="ebookName">{{lastLearnEbook.ebook_name}}</div>
          <el-progress class="ebookProgress" :percentage="lastLearnEbook.progress" :stroke-width="6"></el-progress>
        </div>
        <div class="btnWrap" @click="continueRead">
          <div class="btnText">{{$t('ebook.continue_reading')}}</div>
          <Icon icon="material-symbols:arrow-forward-ios-rounded" class="arrowIcon" />
        </div>
      </div>
      <div class="levelsWrap">
        <template v-for="(levelData, i) in ebookList">
          <div v-if="levelData.list.length > 0" class="levelWrap" :key="i">
            <template v-if="levelData.level === 'Pre-A1'">
              <div class="titleWrap">
                <div class="levelTitle">
                  <div class="levelName">{{$t('ebook.super_simple_dialogues')}}</div>
                </div>
                <router-link class="viewAllBtn" to="/ebook/all/dialogues">{{$t('ebook.all_dialogues')}}</router-link>
              </div>
            </template>
            <template v-else>
              <div class="titleWrap">
                <div class="levelTitle">
                  <div class="levelName">{{levelData.level}}</div>
                  <div class="levelIcon" :class="levelData.level">
                    <div class="iconBar" v-for="i in 4" :key="i"></div>
                  </div>
                </div>
                <router-link class="viewAllBtn" to="/ebook/all">{{$t('ebook.all_ebooks')}}</router-link>
              </div>
            </template>
            <div class="listWrap">
              <div class="swiperBtn prev" :class="'prev' + i">
                <img src="@/assets/images/home/arrow.png" class="arrow">
              </div>
              <swiper class="ebookList" :options="getSwiperOptions(i)">
                <swiper-slide class="ebookWrap" v-for="(ebook, j) in levelData.list" :key="j">
                  <router-link class="ebookLink" :to="`/ebook/${ebook.id}`">
                    <div class="ebookIconWrap">
                      <img class="ebookIcon" :src="ebook.icon" :alt="ebook.ebook_name_trans">
                      <template v-if="ebook.progress === 100">
                        <div class="ebookStatus">
                          <Icon icon="pajamas:check-circle-filled" class="finishIcon" />
                          <div class="statusText">{{$t('ebook.reading_finished')}}</div>
                        </div>
                      </template>
                      <template v-else-if="ebook.progress && ebook.progress > 0">
                        <el-progress class="ebookProgress" :percentage="ebook.progress" :stroke-width="5" :show-text="false"></el-progress>
                      </template>
                    </div>
                    <div class="ebookNameWrap">
                      <div class="ebookNameTrans">{{ebook.ebook_name_trans}}</div>
                      <div class="ebookName">{{ebook.ebook_name}}</div>
                    </div>
                  </router-link>
                </swiper-slide>
              </swiper>
              <div class="swiperBtn next" :class="'next' + i">
                <img src="@/assets/images/home/arrow.png" class="arrow">
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import Vuex from 'vuex'
  import { Icon } from '@iconify/vue2'
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import { getEbookList } from '@/api/ebook'
  import Header from '@/components/Header.vue'
  import '@/assets/styles/ebook/common.scss'
  import 'swiper/css/swiper.css'

  export default {
    data() {
      return {
        swiperOptions: {
          loop: true,
          breakpoints: {
            768: {
              slidesPerView: 5,
              slidesPerGroup: 5,
              spaceBetween: 30
            },
            640: {
              slidesPerView: 4,
              slidesPerGroup: 4,
              spaceBetween: 30
            },
            320: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 20
            }
          }
        },
        ebookList: [],
        lastLearnEbook: null,
        lastLearnTime: 0
      }
    },
    components: {
      'home-header': Header,
      Swiper,
      SwiperSlide,
      Icon
    },
    computed: {
      ...Vuex.mapGetters(['userLanguage', 'learnLanguage'])
    },
    watch: {
      userLanguage() {
        this.init()
      },
      learnLanguage() {
        this.init()
      }
    },
    created() {
      this.init()
    },
    methods: {
      async init() {
        let loadingInstance = this.$loading()
        this.lastLearnEbook = null
        this.lastLearnTime = 0

        let ebookLevelList = [
          { difficulty: 1, level: 'Pre-A1', list: [] },
          { difficulty: 1, level: 'A1', list: [] },
          { difficulty: 2, level: 'A2', list: [] },
          { difficulty: 3, level: 'B1', list: [] },
          { difficulty: 4, level: 'B2', list: [] }
        ]
        let ebookList = await getEbookList(this.userLanguage, this.learnLanguage)

        // 按难度分类
        ebookList.forEach(ebookData => {
          let { level, last_learn_time } = ebookData
          let targetList = ebookLevelList.find(item => item.level === level)
          targetList['list'].push(ebookData)

          // 记录上次阅读的电子书
          if (last_learn_time && last_learn_time > this.lastLearnTime) {
            this.lastLearnTime = last_learn_time
            this.lastLearnEbook = ebookData
          }
        })
        this.ebookList = ebookLevelList

        this.$nextTick(() => {
          loadingInstance.close()
        })
      },
      continueRead() {
        this.$router.push(`/ebook/${this.lastLearnEbook.id}/learn/read`)
      },
      getSwiperOptions(index) {
        let navigation = {
          nextEl: '.next' + index,
          prevEl: '.prev' + index
        }
        return Object.assign({ navigation }, this.swiperOptions)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .ebook {
    background: #FFFFFF;
    min-height: 100vh;
    .contentWrap {
      margin: 80px auto;
      padding: 20px;
      width: 100%;
      max-width: 840px;
      box-sizing: border-box;
      .lastLearnWrap {
        margin: 0 auto;
        margin-top: 30px;
        padding: 20px;
        padding-left: 150px;
        width: 95%;
        min-height: 180px;
        border-radius: 20px;
        position: relative;
        background: #b2b9d31a;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .ebookIcon {
          width: 120px;
          position: absolute;
          left: 10px;
          top: -10px;
          cursor: pointer;
        }
        .ebookWrap {
          cursor: pointer;
          .ebookNameTrans {
            font-weight: 500;
            font-size: 18px;
            color: #000000;
          }
          .ebookName {
            margin-top: 5px;
            font-weight: 700;
            font-size: 14px;
            color: #2b3337ba;
            opacity: 0.9;
          }
          .ebookProgress {
            margin-top: 20px;
            min-width: 240px;
            display: flex;
            align-items: center;
            gap: 15px;
            ::v-deep .el-progress-bar {
              padding-right: 0;
              margin-right: 0;
              .el-progress-bar__outer {
                background: #0000001C;
              }
            }
            ::v-deep .el-progress__text {
              margin-left: 0;
              color: #4388FF;
              font-size: 13px !important;
            }
          }
        }
        .btnWrap {
          display: flex;
          align-items: center;
          gap: 5px;
          color: #4388FF;
          transition: all 0.1s;
          &:hover {
            cursor: pointer;
            opacity: 0.7;
          }
          .btnText {
            font-weight: 700;
            font-size: 14px;
          }
          .arrowIcon {
            width: 12px;
            height: 12px;
          }
        }
      }
      .levelsWrap {
        .levelWrap {
          margin-top: 30px;
          padding-bottom: 20px;
          border-bottom: 1px solid #DCDEF0;
          .titleWrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .levelTitle {
              display: flex;
              align-items: center;
              gap: 10px;
              .levelName {
                font-weight: 700;
                font-size: 24px;
                color: #000000;
              }
              .levelIcon {
                gap: 4px;
                .iconBar {
                  width: 4px;
                  height: 14px;
                  border-radius: 4px;
                }
              }
            }
            .viewAllBtn {
              padding: 0 10px;
              height: 24px;
              line-height: 24px;
              border-radius: 24px;
              background: #4388ff33;
              border: 1px solid #4388FF;
              font-weight: 500;
              font-size: 14px;
              color: #4388FF;
              cursor: pointer;
              transition: all 0.1s;
              &:hover {
                background: #4388ff60;
              }
            }
          }
          .listWrap {
            position: relative;
            .ebookList {
              padding-top: 20px;
              .ebookWrap {
                height: auto;
                .ebookLink {
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  .ebookIconWrap {
                    position: relative;
                    width: 100%;
                    .ebookIcon {
                      width: 100%;
                      transition: all 0.1s;
                    }
                    .ebookStatus {
                      position: absolute;
                      bottom: 20px;
                      left: 50%;
                      transform: translateX(-50%);
                      display: flex;
                      align-items: center;
                      gap: 4px;
                      background: #00000080;
                      padding: 2px 10px;
                      border-radius: 100px;
                      .finishIcon {
                        width: 14px;
                        height: 14px;
                        color: #73FFC0;
                      }
                      .statusText {
                        font-weight: 500;
                        font-size: 12px;
                        color: #FFFFFF;
                        white-space: nowrap;
                      }
                    }
                    .ebookProgress {
                      width: 70%;
                      position: absolute;
                      bottom: 25px;
                      left: 50%;
                      transform: translateX(-50%);
                      ::v-deep .el-progress-bar__outer {
                        background-color: #FFFFFF80;
                      }
                    }
                  }
                  .ebookNameWrap {
                    .ebookNameTrans {
                      font-weight: 400;
                      font-size: 14px;
                      color: #2b3337ba;
                      opacity: 0.9;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    }
                    .ebookName {
                      margin-top: 2px;
                      font-weight: 700;
                      font-size: 16px;
                      color: #000000;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    }
                  }
                  &:hover {
                    cursor: pointer;
                    .ebookIcon {
                      transform: scale(1.1);
                      transform-origin: bottom left;
                    }
                    .ebookNameTrans, .ebookName {
                      color: #4388FF;
                    }
                  }
                }
              }
            }
            .swiperBtn {
              position: absolute;
              top: calc(50% - 25px);
              width: 24px;
              height: 24px;
              display: flex;
              flex-shrink: 0;
              img {
                width: 100%;
              }
              &:hover {
                cursor: pointer;
                opacity: 0.7;
              }
              &:after {
                content: '';
              }
              &.prev {
                left: -48px;
                transform: rotate(180deg);
              }
              &.next {
                right: -48px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .ebook {
      .contentWrap {
        .lastLearnWrap {
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 20px;
          .ebookWrap {
            .ebookProgress {
              min-width: unset;
            }
          }
        }
      }
    }
  }
</style>