<template>
  <div id="app">
    <router-view></router-view>
    <sign-in :ifShow.sync="signInShow"></sign-in>
  </div>
</template>

<script>
  import Vuex from 'vuex'
  import { ref, onValue, set, get } from 'firebase/database'
  import { signInWithCustomToken } from 'firebase/auth'
  import { getMemberStatus, getBigFansStatus } from '@/api/auth'
  import { checkIsMember, checkIsBigFans, checkIsHaveFreeTrial } from '@/utils/memberUtil'
  import { updateHistoryData, initTodayData } from '@/utils/dataUpdateUtil'
  import constants from '@/utils/constants'
  import SignInWindow from '@/components/SignInWindow.vue'

  export default {
    data() {
      return {
        i18nMap: constants.i18nMap,
        signInShow: false
      }
    },
    provide() {
      return {
        signIn: this.signIn
      }
    },
    computed: {
      ...Vuex.mapGetters(['token', 'updateTime', 'userLanguage'])
    },
    components: {
      'sign-in': SignInWindow
    },
    created() {
      this.changeLang()
      this.init()
    },
    methods: {
      signIn() {
        this.signInShow = true
      },
      changeLang() {
        let userBrowserLang = navigator.language || navigator.browserLanguage
        let userLang = this.userLanguage || this.i18nMap[userBrowserLang] || 'en'
        this.$store.commit('set_userLanguage', userLang)
      },
      async init() {
        if (this.token) {
          let userInfo = await this.$store.dispatch("validateToken")
          if (userInfo) {
            let fbToken = userInfo.UID_JWT
            let [ memberStatus, bigFansStatus ] = await Promise.all([
              getMemberStatus(userInfo.UID),
              getBigFansStatus(userInfo.UID),
              signInWithCustomToken(this.auth_core, fbToken),
              signInWithCustomToken(this.auth_srs, fbToken),
              signInWithCustomToken(this.auth_ebook, fbToken)
            ])
            
            this.$store.commit('set_isMember', checkIsMember(memberStatus))
            this.$store.commit('set_isBigFans', checkIsBigFans(bigFansStatus))
            this.$store.commit('set_isHaveFreeTrial', checkIsHaveFreeTrial(memberStatus))
            
            this.initUserInfo(userInfo)
            this.initProgressData(userInfo)
            this.initHistoryData(userInfo)
          }
        }
      },
      initUserInfo(userInfo) {
        let userInfoRef = ref(this.db_core, `users_private/${userInfo.UID}/user_info`)
        get(userInfoRef).then((snapshot) => {
          if (!snapshot.exists()) {
            let user_info = {
              email: userInfo.Email,
              login_methods: userInfo.FromType
            }
            set(userInfoRef, user_info)
          }
        })
      },
      initProgressData(userInfo) {
        let progressRef = ref(this.db_core, `users_private/${userInfo.UID}/learning_progress`)
        onValue(progressRef, (snapshot) => {
          if (snapshot.exists()) {
            this.$store.commit('set_progressData', snapshot.val() || null)
          }
        })
      },
      initHistoryData(userInfo) {
        initTodayData()
        get(ref(this.db_srs, `users_private/${userInfo.UID}/learning_history/last_update_time`)).then((snapshot) => {
          if (snapshot.exists()) {
            let last_update_time = snapshot.val()
            if (this.updateTime !== last_update_time) {
              let recordRef = ref(this.db_srs, `users_private/${userInfo.UID}/learning_history/record`)
              onValue(recordRef, (snapshot) => {
                this.$store.commit('set_historyData', snapshot.val())
                this.$store.commit('set_updateTime', last_update_time)
              })
            }
          } else {
            updateHistoryData(0, 0)
          }
        })
      }
    }
  }
</script>