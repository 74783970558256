<template>
  <div class="ebookList">
    <div class="header">
      <div class="headerWrap">
        <router-link class="backBtn" to="/ebook">
          <Icon icon="material-symbols:arrow-back-ios-new-rounded" class="headerIcon" />
        </router-link>
        <div v-if="isDialogueList" class="headerTitle">{{$t('ebook.all_dialogues')}}</div>
        <div v-else class="headerTitle">{{$t('ebook.all_ebooks')}}</div>
      </div>
    </div>
    <div class="content">
      <div class="contentWrap">
        <template v-for="(ebook, i) in ebookList">
          <router-link class="ebookWrap" :to="`/ebook/${ebook.id}`" :key="i">
            <img v-if="!isDialogueList" class="levelFlagIcon" :src="require(`@/assets/images/ebook/level_${ebook.level}.png`)">
            <div class="bannerWrap">
              <el-image class="ebookBanner" :src="ebook.banner" fit="cover" lazy></el-image>
              <div class="levelWrap">
                <template v-if="ebook.level !== 'Pre-A1'">
                  <div class="levelName">{{getDifficultyTrans(ebook.difficulty)}}</div>
                  <div class="levelIcon" :class="ebook.level">
                    <div class="iconBar" v-for="j in 4" :key="j"></div>
                  </div>
                </template>
              </div>
            </div>
            <div class="infoWrap">
              <div class="ebookNameWrap">
                <div class="ebookName">{{ebook.ebook_name_trans}}</div>
                <Icon icon="material-symbols:arrow-forward-ios-rounded" class="arrowIcon" />
              </div>
              <div class="ebookSummary">{{ebook.ebook_summary_trans}}</div>
            </div>
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import Vuex from 'vuex'
  import { Icon } from '@iconify/vue2'
  import { getEbookList } from '@/api/ebook'
  import '@/assets/styles/ebook/common.scss'

  export default {
    props: ['type'],
    data() {
      return {
        ebookList: []
      }
    },
    components: {
      Icon
    },
    computed: {
      ...Vuex.mapGetters(['userLanguage', 'learnLanguage']),
      isDialogueList() {
        return this.type && this.type === 'dialogues'
      }
    },
    created() {
      this.init()
    },
    methods: {
      async init() {
        let loadingInstance = this.$loading()

        let ebookList = await getEbookList(this.userLanguage, this.learnLanguage)
        if (this.isDialogueList) {
          this.ebookList = ebookList.filter(ebook => ebook.level === 'Pre-A1')
        } else {
          this.ebookList = ebookList.filter(ebook => ebook.level !== 'Pre-A1')
        }

        this.$nextTick(() => {
          loadingInstance.close()
        })
      },
      getDifficultyTrans(difficulty) {
        const difficultyMap = ['easy', 'normal', 'normal', 'hard']
        return this.$t(`ebook.${difficultyMap[difficulty - 1]}`)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .ebookList {
    background: #EEEEEE;
    min-height: 100vh;
    .header {
      position: sticky;
      left: 0;
      top: 0;
      width: 100vw;
      height: 80px;
      background: #FFFFFF;
      border-bottom: 1px solid #C5C6D2;
      z-index: 1000;
      .headerWrap {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        padding: 0 20px;
        width: 100%;
        max-width: 1000px;
        height: 100%;
        box-sizing: border-box;
        .backBtn {
          display: flex;
          position: absolute;
          left: 20px;
          cursor: pointer;
          .headerIcon {
            width: 32px;
            height: 32px;
            color: #333333;
          }
        }
        .headerTitle {
          font-weight: 500;
          font-size: 26px;
          color: #333333;
        }
      }
    }
    .content {
      width: 100vw;
      .contentWrap {
        width: 100%;
        max-width: 880px;
        margin: 0 auto;
        padding: 40px 20px 80px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px 40px;
        box-sizing: border-box;
        .ebookWrap {
          position: relative;
          width: 400px;
          border-radius: 10px;
          background: #FFFFFF;
          &:hover {
            cursor: pointer;
            .ebookBanner {
              transform: scale(1.05);
            }
            .infoWrap {
              color: #4388FF;
            }
          }
          .levelFlagIcon {
            position: absolute;
            top: -7px;
            right: 30px;
            width: 66px;
            z-index: 100;
          }
          .bannerWrap {
            position: relative;
            width: 100%;
            height: 200px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            overflow: hidden;
            .ebookBanner {
              width: 100%;
              height: 100%;
              transition: all 0.2s;
            }
            .levelWrap {
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 30px;
              padding: 0 20px;
              background: #00000040;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              .levelName {
                font-weight: 500;
                font-size: 12px;
                color: #FFFFFF;
              }
              .levelIcon {
                margin-left: 10px;
                gap: 3px;
                .iconBar {
                  width: 3px;
                  height: 10px;
                  border-radius: 3px;
                }
              }
            }
          }
          .infoWrap {
            padding: 20px;
            color: #2B3337;
            .ebookNameWrap {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .ebookName {
                font-weight: 500;
                font-size: 18px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .arrowIcon {
                flex-shrink: 0;
                width: 20px;
                height: 20px;
              }
            }
            .ebookSummary {
              margin-top: 10px;
              opacity: 0.64;
              font-weight: 400;
              font-size: 14px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .ebookList {
      .header {
        height: 60px;
      }
      .content {
        .contentWrap {
          gap: 20px;
          .ebookWrap {
            width: 100%;
            max-width: 400px;
          }
        }
      }
    }
  }
</style>