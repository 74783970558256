import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../i18n'
import constants from '../utils/constants'
import EncryptDataUtil from '../utils/encryptDataUtil'
import { getToken, removeToken } from '../utils/authUtil'
import { getUserInfo, userSignOut } from '../api/auth'
import { signOut } from 'firebase/auth'

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    testSettings: {
      modelFilter: ''
    },
    userLanguage: '',
    learnLanguage: '',
    token: getToken(),
    userInfo: null,
    isMember: false,
    isBigFans: false,
    isHaveFreeTrial: false,
    accountType: 'free',
    courseSettings: null,
    ebookSettings: null,
    productsInfo: null,
    clipsLessonInfo: null,
    coursesLessonInfo: null,
    coursesDialogueInfo: null,
    coursesGrammarInfo: null,
    coursesUnitsData: null,
    progressData: null,  // 所有语言的进度数据
    historyData: null,  // 20221101:100_30; 日期:学习时长(s)_获得xp
    updateTime: localStorage.getItem('updateTime'),  // 根据此参数判断是否同步数据
    createTime: localStorage.getItem('createTime'),  // 第一次访问站点的时间
  }
}

export default new Vuex.Store({
  state: getDefaultState(),
  getters: {
    testSettings: state => state.testSettings,
    token: state => state.token,
    userInfo: state => state.userInfo,
    isMember: state => state.isMember,
    isBigFans: state => state.isBigFans,
    isHaveFreeTrial: state => state.isHaveFreeTrial,
    accountType: state => state.accountType,
    courseSettings: state => {
      if (state.courseSettings) {
        return state.courseSettings
      } else if (localStorage.getItem('courseSettings')) {
        return JSON.parse(localStorage.getItem('courseSettings'))
      } else {
        return constants.courseDefaultSettings
      }
    },
    ebookSettings: state => {
      if (state.ebookSettings) {
        return state.ebookSettings
      } else if (localStorage.getItem('ebookSettings')) {
        return JSON.parse(localStorage.getItem('ebookSettings'))
      } else {
        return constants.ebookDefaultSettings
      }
    },
    productsInfo: state => state.productsInfo,
    clipsLessonInfo: state => state.clipsLessonInfo,
    coursesLessonInfo: state => state.coursesLessonInfo,
    coursesDialogueInfo: state => state.coursesDialogueInfo,
    coursesGrammarInfo: state => state.coursesGrammarInfo,
    coursesUnitsData: state => state.coursesUnitsData,
    userLanguage: state => {
      return state.userLanguage || localStorage.getItem('lang')
    },
    learnLanguage: state => {
      return state.learnLanguage || localStorage.getItem('learnLang') || 'en'
    },
    progressData: state => {
      if (state.progressData) {
        return state.progressData
      }
      if (localStorage.getItem('pd')) {
        return JSON.parse(EncryptDataUtil.decryptData(localStorage.getItem('pd')))
      }
    },
    historyData: state => {
      if (state.historyData) {
        return state.historyData
      }
      if (localStorage.getItem('hd')) {
        return JSON.parse(EncryptDataUtil.decryptData(localStorage.getItem('hd')))
      }
    },
    updateTime: state => state.updateTime,
    createTime: state => {
      if (state.createTime) {
        return state.createTime
      } else {
        let now = Date.parse(new Date())
        localStorage.setItem('createTime', now)
        return now
      }
    }
  },
  mutations: {
    reset_state(state) {
      Object.assign(state, getDefaultState())
    },
    set_testSettings(state, testSettings) {
      state.testSettings = testSettings
    },
    set_userLanguage(state, lang) {
      state.userLanguage = lang
      localStorage.setItem('lang', lang)
      i18n.locale = lang
      document.querySelector('html').setAttribute('lang', constants.langsData[lang]['htmlLang'])
    },
    set_learnLanguage(state, lang) {
      state.learnLanguage = lang
      localStorage.setItem('learnLang', lang)
    },
    set_userInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    set_isMember(state, isMember) {
      state.isMember = isMember
    },
    set_isBigFans(state, isBigFans) {
      state.isBigFans = isBigFans
    },
    set_isHaveFreeTrial(state, isHaveFreeTrial) {
      state.isHaveFreeTrial = isHaveFreeTrial
    },
    set_accountType(state, accountType) {
      state.accountType = accountType
    },
    set_courseSettings(state, courseSettings) {
      state.courseSettings = courseSettings
      localStorage.setItem('courseSettings', JSON.stringify(courseSettings))
    },
    set_ebookSettings(state, ebookSettings) {
      state.ebookSettings = ebookSettings
      localStorage.setItem('ebookSettings', JSON.stringify(ebookSettings))
    },
    set_productsInfo(state, productsInfo) {
      state.productsInfo = productsInfo
    },
    set_clipsLessonInfo(state, lessonInfo) {
      state.clipsLessonInfo = lessonInfo
      localStorage.setItem('currentClipsLessonID', lessonInfo.lsID)
    },
    set_coursesLessonInfo(state, lessonInfo) {
      state.coursesLessonInfo = lessonInfo
      if (lessonInfo.type !== 'testout') {
        localStorage.setItem('currentCoursesLesson', `${lessonInfo.id}_${lessonInfo.type}`)
      }
    },
    set_coursesDialogueInfo(state, dialogueInfo) {
      state.coursesDialogueInfo = dialogueInfo
      localStorage.setItem('currentCoursesLesson', `${dialogueInfo.id}_${dialogueInfo.type}`)
    },
    set_coursesGrammarInfo(state, grammarInfo) {
      state.coursesGrammarInfo = grammarInfo
    },
    set_coursesUnitsData(state, coursesUnitsData) {
      state.coursesUnitsData = coursesUnitsData
    },
    set_progressData(state, progressData) {
      state.progressData = progressData
      localStorage.setItem('pd', EncryptDataUtil.encryptData(JSON.stringify(progressData)))
    },
    set_historyData(state, historyData) {
      state.historyData = historyData
      localStorage.setItem('hd', EncryptDataUtil.encryptData(JSON.stringify(historyData)))
    },
    set_updateTime(state, updateTime) {
      state.updateTime = updateTime
      localStorage.setItem('updateTime', updateTime)
    }
  },
  actions: {
    async validateToken({ commit, state }) {
      let userInfo = await getUserInfo(state.token)
      if (userInfo) {
        commit('set_userInfo', userInfo)
      }
      return Promise.resolve(userInfo)
    },
    async logout({ commit, state }) {
      let token = state.token
      await signOut(window.auth)
      await signOut(window.auth_core)
      await signOut(window.auth_srs)
      await userSignOut(token)
      removeToken()
      commit('reset_state')
      localStorage.clear()
      window.location.href = constants.domain
    }
  }
})