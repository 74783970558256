<template>
  <div class="ebookLearn" :class="['theme_' + ebookSettings.theme]" :style="cssVars" @click="hideTooltip">
    <div class="header">
      <div class="headerWrap">
        <router-link class="backBtn" :to="`/ebook/${ebookID}`">
          <Icon icon="material-symbols:arrow-back-ios-new-rounded" class="headerIcon" />
        </router-link>
        <div class="btnWrap">
          <div class="btn changeThemeBtn" @click.stop="changeThemeWindowShow = true"><img class="btnIcon" src="@/assets/images/ebook/changeTheme.png"></div>
          <div class="btn settingBtn" @click.stop="settingWindowShow = true"><img class="btnIcon" src="@/assets/images/ebook/setting.png"></div>
          <div class="btn wordShorthandBtn" @click.stop="wordShorthandClickHandle">
            <img class="btnIcon" src="@/assets/images/ebook/wordShorthand.png">
            <div class="btnNum">{{wordFavNum}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="content" v-if="ebookData">
      <div class="contentWrap">
        <template v-for="(sent, sentIndex) in ebookData">
          <div class="sentWrap" ref="sentWrap" :key="sentIndex">
            <div class="playBtn" :class="{ show: !audioPlay }" @click="playSentAudio(sentIndex)">
              <Icon icon="pajamas:play" class="sentIcon"></Icon>
            </div>
            <div class="sent">
              <template v-for="(trunk, trunkIndex) in sent.trunks">
                <template v-if="typeof trunk === 'string'">{{trunk}}</template>
                <span v-else class="trunk" :class="{ bg: currentAudioSentID === sent.sentID && currentAudioTrunkID === trunk.trunkID }" :key="trunkIndex">
                  <template v-for="(word, wordIndex) in trunk.words">
                    <template v-if="typeof word === 'string'">{{word}}</template>
                    <span v-else-if="!ifShowTrans(word)"
                      class="word"
                      :class="{ bg: currentAudioSentID === sent.sentID && currentAudioTrunkID === trunk.trunkID && currentAudioWordID === word.wordID }"
                      :key="wordIndex">{{word.word}}</span>
                    <span v-else 
                      class="word underline"
                      :class="{ 
                        highlight: ifHighlight(word),
                        bg: currentAudioSentID === sent.sentID && currentAudioTrunkID === trunk.trunkID && currentAudioWordID === word.wordID
                      }"
                      @click.stop="showWordTrans($event, sent, trunk, word, sentIndex)"
                      :key="wordIndex">{{word.word}}</span>
                  </template>
                </span>
              </template>
            </div>
            <div class="transBtn" ref="sentTransBtn" @click.stop="showSentTrans(sent.sentID, sentIndex)">
              <Icon icon="cuida:translate-outline" class="sentIcon"></Icon>
            </div>
          </div>

          <!-- 付费蒙版 -->
          <template v-if="!isMember && (sentIndex + 1) === freeSentNum">
            <div class="buyNowWrap" :key="sentIndex + '_1'">
              <div class="dashedLine"></div>
              <div class="buyBtn" ref="buyBtn" @click="buyBtnClickHandle">{{$t('ebook.subscribe_for_full_access')}}</div>
              <div class="buyBtn fixed animate__animated animate__faster animate__fadeInDown" ref="buyBtnFixed" @click="buyBtnClickHandle">{{$t('ebook.subscribe_for_full_access')}}</div>
            </div>
            <div class="contentMask" :key="sentIndex + '_2'"></div>
          </template>
        </template>
      </div>
    </div>

    <div class="footer">
      <div class="footerWrap">
        <div class="loopBtn" @click="audioLoop = !audioLoop">
          <Icon icon="ri:repeat-one-line" v-if="audioLoop" class="footerIcon" />
          <Icon icon="ri:repeat-2-line" v-else class="footerIcon light" />
        </div>
        <div class="playBtn" @click="playAudio">
          <Icon icon="material-symbols-light:pause-rounded" v-if="audioPlay" class="btnIcon"></Icon>
          <Icon icon="material-symbols:play-arrow-rounded" v-else class="btnIcon"></Icon>
        </div>
        <div class="resetBtn" @click="resetAudio">
          <Icon icon="material-symbols:clock-loader-60-sharp" class="footerIcon" />
        </div>
      </div>
      <div class="footerMask" ref="footerMask"></div>
    </div>

    <!-------------------- tooltips --------------------->

    <!-- sent tooltip -->
    <div class="tooltip" ref="sentTransTooltip" v-show="sentTransShow" @click.stop>
      <template v-if="sentTransLoading"><i class="el-icon-loading"></i></template>
      <template v-else>{{sentTrans}}</template>
      <div class="arrow" data-popper-arrow></div>
    </div>

    <!-- word tooltip -->
    <div class="tooltip" ref="wordTransTooltip" v-show="wordTransShow" @click.stop>
      <!-- <div class="iconWrap refreshBtn" @click="refreshWord">
        <Icon icon="ant-design:reload-outlined" class="icon" />
      </div> -->
      <div class="wordTxt">{{wordTxt}}</div>
      <div class="divider"></div>
      <div class="wordTrans">
        <template v-if="wordTransLoading"><i class="el-icon-loading"></i></template>
        <template v-else>{{wordTrans}}</template>
      </div>
      <div class="divider"></div>
      <div class="wordIcons">
        <div class="iconWrap" @click="bugReportWindowShow = true">
          <Icon icon="fa:bug" class="icon" />
        </div>
        <div v-if="wordFav" class="iconWrap" @click="setFavWord(false)">
          <Icon icon="streamline:star-1-solid" class="icon blue" />
        </div>
        <div v-else class="iconWrap" @click="setFavWord(true)">
          <Icon icon="streamline:star-1" class="icon" />
        </div>
        <div class="audioGray" ref="audio" @click="playWordAudio"></div>
        <div class="iconWrap" @click="wordExplainWindowShow = true">
          <Icon icon="akar-icons:full-screen" class="icon" />
        </div>
      </div>
      <div class="arrow" data-popper-arrow></div>
    </div>

    <!-------------------- dialogs --------------------->

    <!-- changeTheme window -->
    <el-dialog
      custom-class="changeThemeWindow"
      :visible.sync="changeThemeWindowShow"
      :top="`calc(50vh - 65px)`"
      :show-close="false"
      append-to-body
      >
      <div class="dialogWrap">
        <div class="settingName">{{$t('ebook.ebook_themes')}}</div>
        <div class="themeWrap">
          <div v-for="(themeData, index) in themeList"
            class="themeItem"
            :class="{ active: ebookSettings.theme === themeData.theme }"
            :style="{ background: themeData.background }"
            @click="themeChange(themeData.theme)"
            :key="index">
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- setting window -->
    <el-dialog
      custom-class="settingWindow"
      :visible.sync="settingWindowShow"
      :top="`calc(50vh - 115px)`"
      :show-close="false"
      append-to-body
      >
      <div class="dialogWrap">
        <div class="settingItem">
          <div class="settingName">{{$t('ebook.font_size')}}</div>
          <div class="settingWrap">
            <div class="iconWrap" @click="fontSizeChange('minus')">
              <Icon icon="lsicon:minus-outline" class="settingIcon" />
            </div>
            <div class="settingValue">{{fontSize}}</div>
            <div class="iconWrap" @click="fontSizeChange('add')">
              <Icon icon="lsicon:circle-add-outline" class="settingIcon" />
            </div>
          </div>
        </div>
        <div class="settingItem">
          <div class="settingName">{{$t('ebook.audio_speed')}}</div>
          <div class="settingWrap">
            <div class="iconWrap" @click="playSpeedChange('minus')">
              <Icon icon="lsicon:minus-outline" class="settingIcon" />
            </div>
            <div class="settingValue">{{playSpeed}}</div>
            <div class="iconWrap" @click="playSpeedChange('add')">
              <Icon icon="lsicon:circle-add-outline" class="settingIcon" />
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="settingItem">
          <div class="settingName">{{$t('ebook.pos_tint')}}</div>
          <el-switch
            v-model="highlight"
            active-color="#4388FF">
          </el-switch>
        </div>
        <div class="settingItem highlightPosSetting" :class="{ disabled: !ebookSettings.highlight }">
          <div class="colorBlock"></div>
          <el-dropdown trigger="click" placement="bottom" @command="highlightPosChange" :disabled="!ebookSettings.highlight">
            <div class="dropdownLink">
              <span class="dropdownName">{{highlightPos}}</span>
              <Icon icon="fe:drop-down" class="dropdownArrow"></Icon>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(posData, index) in highlightPosPrefixList"
                :key="index"
                :command="posData.pos">
                {{$t(`ebook.ebook_pos_${posData.name}`)}}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </el-dialog>

    <!-- word shorthand tips window -->
    <el-dialog
      custom-class="wordShorthandTipsWindow"
      :visible.sync="wordShorthandTipsWindowShow"
      :top="`calc(50vh - 100px)`"
      :show-close="false"
      append-to-body
      >
      <div class="dialogWrap">
        <div class="dialogTitle">{{$t('ebook.ebook_fav_not_enough_title')}}</div>
        <div class="tipsContent">{{$t('ebook.ebook_fav_not_enough_content')}}</div>
        <div class="confirmBtn" @click="wordShorthandTipsWindowShow = false">{{$t('main.confirm')}}</div>
      </div>
    </el-dialog>

    <!-- bug report window -->
    <el-dialog
      custom-class="bugReportWindow"
      :visible.sync="bugReportWindowShow"
      :top="`calc(50vh - 100px)`"
      :show-close="false"
      append-to-body
      @closed="bugReportType = 0"
      >
      <div class="dialogWrap">
        <div class="dialogTitle">{{$t('ebook.ebook_bugreport_title')}}</div>
        <el-radio-group class="bugReportRadio" v-model="bugReportType">
          <el-radio :label="1">{{$t('ebook.ebook_bugreport_issue_1')}}</el-radio>
          <el-radio :label="2">{{$t('ebook.ebook_bugreport_issue_2')}}</el-radio>
        </el-radio-group>
        <div class="btnWrap">
          <div class="cancelBtn" @click="bugReportWindowShow = false">{{$t('main.cancel')}}</div>
          <div class="confirmBtn" :class="{ active: !!bugReportType }" @click="!!bugReportType && sendBugReport(bugReportType)">{{$t('main.confirm')}}</div>
        </div>
      </div>
    </el-dialog>

    <!-- word explain window -->
    <el-dialog
      custom-class="wordExplainWindow"
      :visible.sync="wordExplainWindowShow"
      :top="`calc(50vh - 250px)`"
      :show-close="false"
      append-to-body
      >
      <div class="dialogWrap" v-if="currentShowWord && currentShowWord.explain">
        <div class="audioIcon" ref="wordExplainAudio" @click="playWordAudio('wordExplain')"></div>
        <!-- 单词 -->
        <div class="wordOriginal">{{currentShowWord.word}}</div>
        <!-- 音标 -->
        <div class="wordPronunciation">{{currentShowWord.explain.pronunciationGuides}}</div>
        <!-- 解释 -->
        <div class="wordDefinition" v-if="learnLanguage === 'es'">{{currentShowWord.explain.simpleSpanishDefinitions}}</div>
        <div class="wordDefinition" v-else-if="learnLanguage === 'fr'">{{currentShowWord.explain.simpleFrenchDefinitions}}</div>
        <div class="wordDefinition" v-else>{{currentShowWord.explain.englishDefinitions}}</div>
        <!-- 词性和翻译 -->
        <div class="wordPosAndTrans">
          <div class="wordPos">{{currentShowWord.explain.theWordForm}}</div>
          <div class="wordTrans">{{currentShowWord.trans}}</div>
        </div>
        <div class="sentExampleWrap">
          <div class="wrapTitle">{{$t('ebook.example_sentence')}}</div>
          <div class="wrapSent" v-if="learnLanguage === 'es'">{{currentShowWord.explain.newSpanishExampleSentence}}</div>
          <div class="wrapSent" v-else-if="learnLanguage === 'fr'">{{currentShowWord.explain.newFrenchExampleSentence}}</div>
          <div class="wrapSent" v-else>{{currentShowWord.explain.newExampleSentence}}</div>
        </div>
        <div class="sentOriginalWrap">
          <div class="wrapTitle">{{$t('ebook.original_sentence')}}</div>
          <div class="wrapSent">{{currentShowWord.sentTxt}}</div>
        </div>
        <div class="backBtn" @click="wordExplainWindowShow = false">{{$t('ebook.ebook_quick_back')}}</div>
      </div>
    </el-dialog>

    <!-- word shorthand window -->
    <el-drawer
      custom-class="wordShorthandWindow"
      :visible.sync="wordShorthandWindowShow"
      size="100%"
      append-to-body
      destroy-on-close
      >
      <word-shorthand
        :ebookID="ebookID"
        :favWordList="favWordList"
        @hideWindow="wordShorthandWindowShow = false">
      </word-shorthand>
    </el-drawer>

    <!-- global audio -->
    <audio ref="sentAudio" @play="onSentAudioPlay" @pause="onSentAudioPause" @ended="onSentAudioEnded"></audio>
  </div>
</template>

<script>
  import _ from 'lodash'
  import Vuex from 'vuex'
  import localforage from 'localforage'
  import { Icon } from '@iconify/vue2'
  import { createPopper } from '@popperjs/core'
  import { getOneEbook, getSentTrans, getWordTransAndExplain, sendBugReport, getFavWords, setFavWord, setEbookProgress } from '@/api/ebook'
  import { playAnimAudio } from '@/utils/commonUtil'
  import constants from '@/utils/constants'
  import WordShorthand from '@/components/ebook/WordShorthand.vue'
  import 'animate.css'

  // 主题列表
  const themeList = [
    { theme: 1, background: '#EEEEEE', highlight: '#4388FF', text: '#171819' },
    { theme: 2, background: '#E4DECD', highlight: '#C57900', text: '#171819' },
    { theme: 3, background: '#C9E5CD', highlight: '#01A37A', text: '#171819' },
    { theme: 4, background: '#333333', highlight: '#4388FF', text: '#7C7C7C' },
  ]

  // 高亮词性前缀列表，例如选择"JJ"，则"JJ", "JJR", "JJS"都高亮
  const highlightPosPrefixList = [
    { pos: 'JJ', name: 'adjective' },
    { pos: 'NN', name: 'noun' },
    { pos: 'RB', name: 'adverb' },
    { pos: 'VB', name: 'verb' }
  ]

  export default {
    inject: ['signIn'],
    components: {
      'word-shorthand': WordShorthand,
      Icon
    },
    data() {
      return {
        paragraphID: '001', // 现在没有段落的概念了，所以固定为001
        localData: null,
        ebookData: null,
        freeSentNum: 8,
        themeList: themeList,
        highlightPosPrefixList: highlightPosPrefixList,
        changeThemeWindowShow: false,
        settingWindowShow: false,
        wordShorthandWindowShow: false,
        wordShorthandTipsWindowShow: false,
        wordExplainWindowShow: false,
        bugReportWindowShow: false,
        bugReportType: 0,
        defaultFontSize: 20,
        defaultLineHeight: 40,
        favWords: null,
        favWordList: [],
        sentTrans: '',
        sentTransShow: false,
        sentTransLoading: false,
        currentShowWord: null,
        wordTransShow: false,
        wordTransLoading: false,
        audioPlay: false,
        audioLoop: false,
        audio: null,
        audioTimer: null,
        currentAudioSentID: '',
        currentAudioTrunkID: '',
        currentAudioWordID: ''
      }
    },
    props: ['ebookID', 'learnMode'],
    watch: {
      userInfo: {
        handler(val) {
          let uid = val?.UID
          if (uid) {
            getFavWords(uid, this.learnLanguage, this.ebookID, (favWords) => {
              this.favWords = favWords
            })
          }
        },
        immediate: true
      }
    },
    computed: {
      ...Vuex.mapGetters(['userInfo', 'isMember', 'userLanguage', 'learnLanguage', 'ebookSettings']),
      cssVars() {
        let { theme, fontSize } = this.ebookSettings
        let currentTheme = this.themeList.find(themeData => themeData.theme === theme)
        return {
          '--ebook-bg-color': currentTheme['background'],
          '--ebook-hl-color': currentTheme['highlight'],
          '--ebook-text-color': currentTheme['text'],
          '--ebook-font-size': `${this.defaultFontSize * fontSize}px`,
          '--ebook-line-height': `${this.defaultLineHeight * fontSize}px`
        }
      },
      wordTxt() {
        let wordTxt = ''
        if (this.currentShowWord) {
          let { word, lemma, pos } = this.currentShowWord
          if (word.toLowerCase() !== lemma.toLowerCase() && pos !== 'phrase') {
            wordTxt = `${word} / ${lemma}`
          } else {
            wordTxt = word.toLowerCase()
          }
        }
        return wordTxt
      },
      wordTrans() {
        return this.currentShowWord?.trans || ''
      },
      wordFav() {
        let wordFav = false
        if (this.currentShowWord && this.favWords) {
          let { favID } = this.currentShowWord
          let word = this.favWords[favID]
          if (word) {
            wordFav = word['fav_status']
          }
        }
        return wordFav
      },
      wordFavNum() {
        let wordFavNum = 0
        if (this.favWords) {
          for (let key in this.favWords) {
            if (this.favWords[key]['fav_status']) {
              wordFavNum ++
            }
          }
        }
        return wordFavNum
      },
      fontSize: {
        get() {
          let { fontSize } = this.ebookSettings
          return `${fontSize.toFixed(1)}X`
        },
        set(val) {
          let ebookSettings = this.ebookSettings
          ebookSettings.fontSize = val
          this.$store.commit('set_ebookSettings', ebookSettings)
        }
      },
      playSpeed: {
        get() {
          let { playSpeed } = this.ebookSettings
          return `${playSpeed.toFixed(1)}X`
        },
        set(val) {
          let ebookSettings = this.ebookSettings
          ebookSettings.playSpeed = val
          this.audio.playbackRate = val
          this.audio.defaultPlaybackRate = val
          this.$store.commit('set_ebookSettings', ebookSettings)
        }
      },
      highlight: {
        get() {
          return this.ebookSettings.highlight
        },
        set(val) {
          let ebookSettings = this.ebookSettings
          ebookSettings.highlight = val
          this.$store.commit('set_ebookSettings', ebookSettings)
        }
      },
      highlightPos: {
        get() {
          let { highlightPos } = this.ebookSettings
          let currentHighlightPos = this.highlightPosPrefixList.find(posData => posData.pos === highlightPos)
          return this.$t(`ebook.ebook_pos_${currentHighlightPos.name}`)
        },
        set(val) {
          let ebookSettings = this.ebookSettings
          ebookSettings.highlightPos = val
          this.$store.commit('set_ebookSettings', ebookSettings)
        }
      }
    },
    created() {
      this.init()
      window.addEventListener('scroll', this.buyBtnHandle)
      window.addEventListener('scroll', this.progressHandle)

      this.$nextTick(() => {
        this.audio = this.$refs.sentAudio
        this.audio.defaultPlaybackRate = this.ebookSettings.playSpeed
      })
    },
    destroyed() {
      window.removeEventListener('scroll', this.buyBtnHandle)
      window.removeEventListener('scroll', this.progressHandle)
      clearTimeout(this.delayTimer)
    },
    methods: {
      async init() {
        let loadingInstance = this.$loading()

        this.localData = await getOneEbook(this.learnLanguage, this.ebookID)
        let { sentences, details } = this.localData
        let sentArr = []
        for (let sentID in sentences) {
          let sentTxt = sentences[sentID]
          let trunks = details[sentID]

          let trunkArr = []
          let trunkEntries = Object.entries(trunks)
          trunkEntries.forEach(([trunkID, trunkData], trunkIndex) => {
            let trunkEntriesPrev = trunkEntries[trunkIndex - 1]
            if (trunkEntriesPrev) {
              let trunkPrev = trunkEntriesPrev[1]
              if (trunkPrev.end_index !== trunkData.start_index) {
                let splitStr = sentTxt.slice(parseInt(trunkPrev.end_index), parseInt(trunkData.start_index))
                trunkArr.push(splitStr)
              }
            }

            let wordArr = []
            let wordEntries = Object.entries(trunkData.words_array)
            wordEntries.forEach(([wordID, wordData], wordIndex) => {
              let wordEntriesPrev = wordEntries[wordIndex - 1]
              if (wordEntriesPrev) {
                let wordPrev = wordEntriesPrev[1]
                if (wordPrev.end_index !== wordData.start_index) {
                  let splitStr = sentTxt.slice(parseInt(wordPrev.end_index), parseInt(wordData.start_index))
                  wordArr.push(splitStr)
                }
              } else {
                if (trunkData.start_index !== wordData.start_index) {
                  let splitStr = sentTxt.slice(parseInt(trunkData.start_index), parseInt(wordData.start_index))
                  wordArr.push(splitStr)
                }
              }
              
              let favID = `${this.ebookID}${this.paragraphID}${sentID}${trunkID}${wordID}`
              let word = Object.assign({ wordID, favID }, wordData)
              wordArr.push(word)

              let wordEntriesNext = wordEntries[wordIndex + 1]
              if (!wordEntriesNext) {
                if (wordData.end_index !== trunkData.end_index) {
                  let splitStr = sentTxt.slice(parseInt(wordData.end_index), parseInt(trunkData.end_index))
                  wordArr.push(splitStr)
                }
              }
            })

            let trunk = { trunkID, words: wordArr, audio_time_1: trunkData.audio_time_1 }
            let trunkEntriesNext = trunkEntries[trunkIndex + 1]
            if (trunkEntriesNext) {
              let trunkNext = trunkEntriesNext[1]
              trunk.audio_time_2 = trunkNext.audio_time_1
            }
            trunkArr.push(trunk)
          })

          let sent = { sentID, sentTxt, trunks: trunkArr }
          sentArr.push(sent)
        }
        this.ebookData = sentArr.sort((a, b) => parseInt(a.sentID) - parseInt(b.sentID))

        this.$nextTick(async () => {
          // 如果有进度数据，跳到上次学习的地方
          let ebookProgressData = await localforage.getItem('ebookProgressData')
          let ebookProgress = ebookProgressData?.[this.learnLanguage]?.[this.ebookID]
          if (ebookProgress) {
            let { progress } = ebookProgress
            let scrollHeight = document.documentElement.scrollHeight
            let clientHeight = document.documentElement.clientHeight
            let scrollTop = Math.round((scrollHeight - clientHeight) * (progress / 100))
            window.scrollTo({ top: scrollTop })
          }

          // 听书模式需要从当前进度开始播放
          if (this.learnMode === 'listen') {
            for (let i = 0; i < this.$refs.sentWrap.length; i++) {
              let sentWrap = this.$refs.sentWrap[i]
              let rect = sentWrap.getBoundingClientRect()
              if (rect.top > 120) {
                this.playSentAudio(i)
                break
              }
            }
          }

          loadingInstance.close()
        })
      },
      ifSentUnlock(sentIndex) {
        if (this.isMember || (sentIndex + 1) <= this.freeSentNum) {
          return true
        } else {
          return false
        }
      },
      ifShowTrans(word) {
        let showTransPos
        let excludeRule = word.pos == "VBD" && word.lemma == "be"

        if (['es', 'fr'].includes(this.learnLanguage)) {
          showTransPos = ["ADJ", "ADV", "NOUN", "PROPN", "VERB"]
        } else {
          showTransPos = ["JJ", "JJR", "JJS", "NN", "NNS", "RB", "RBR", "RBS", "VB", "VBD", "VBG", "VBN", "VBP", "VBZ"]
        }
        
        return showTransPos.includes(word.pos) && !excludeRule
      },
      ifHighlight(word) {
        let highlightRule = this.highlight && word.pos.startsWith(this.ebookSettings.highlightPos)

        return this.ifShowTrans(word) && highlightRule
      },
      themeChange(theme) {
        let ebookSettings = this.ebookSettings
        ebookSettings.theme = theme
        this.$store.commit('set_ebookSettings', ebookSettings)
      },
      fontSizeChange(type) {
        let { fontSize } = this.ebookSettings
        if (type === 'minus' && fontSize > 0.5) {
          fontSize = parseFloat((fontSize - 0.1).toFixed(1))
        } else if (type === 'add' && fontSize < 1.5) {
          fontSize = parseFloat((fontSize + 0.1).toFixed(1))
        }
        this.fontSize = fontSize
      },
      playSpeedChange(type) {
        let { playSpeed } = this.ebookSettings
        if (type === 'minus' && playSpeed > 0.5) {
          playSpeed = parseFloat((playSpeed - 0.1).toFixed(1))
        } else if (type === 'add' && playSpeed < 1.5) {
          playSpeed = parseFloat((playSpeed + 0.1).toFixed(1))
        }
        this.playSpeed = playSpeed
      },
      highlightPosChange(pos) {
        this.highlightPos = pos
      },
      wordShorthandClickHandle() {
        if (this.wordFavNum < 5) {
          this.wordShorthandTipsWindowShow = true
        } else {
          let { sentences, details } = this.localData
          let favWordList = []
          for (let favID in this.favWords) {
            let favWord = this.favWords[favID]
            let sentID = favID.slice(6, 9)
            let trunkID = favID.slice(9, 12)
            let wordID = favID.slice(12, 15)
            if (favWord['fav_status'] === true) {
              let sent = sentences[sentID]
              let wordData = details[sentID][trunkID]['words_array'][wordID]
              let wordLowerCase = wordData.word.toLowerCase()
              let wordObj = {...favWord, favID, word: wordLowerCase, sent }
              favWordList.push(wordObj)
            }
          }
         
          this.favWordList = favWordList
          this.wordShorthandWindowShow = true
        }
      },
      showSentTrans(sentID, sentIndex) {
        if (this.ifSentUnlock(sentIndex)) {
          if (this.audioPlay) {
            this.audioPlay = false
            this.audio.pause()
          }
          
          this.hideTooltip()
          this.sentTransLoading = true
          this.sentTransShow = true
  
          this.$nextTick(async () => {
            let tooltip = this.$refs.sentTransTooltip
            let button = this.$refs.sentTransBtn[sentIndex]
            let popperInstance = createPopper(button, tooltip, {
              placement: 'top',
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 5]
                  }
                },
                {
                  name: 'preventOverflow',
                  options: {
                    padding: 20
                  }
                }
              ]
            })
            
            let sentTrans = await getSentTrans(this.userLanguage, this.learnLanguage, this.ebookID, sentID)
            this.sentTrans = sentTrans
            this.sentTransLoading = false
            popperInstance.update()
          })
        }
      },
      showWordTrans(e, sent, trunk, word, sentIndex) {
        if (this.ifSentUnlock(sentIndex)) {
          if (this.audioPlay) {
            this.audioPlay = false
            this.audio.pause()
          }
  
          this.hideTooltip()
          this.wordTransLoading = true
          this.wordTransShow = true
  
          this.currentShowWord = word
          this.currentShowWord['sentTxt'] = sent.sentTxt
          this.currentShowWord['sentID'] = sent.sentID
          this.currentShowWord['trunkID'] = trunk.trunkID
          this.playWordAudio()
  
          this.$nextTick(async () => {
            let tooltip = this.$refs.wordTransTooltip
            let popperInstance = createPopper(e.target, tooltip, {
              placement: 'bottom',
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 8]
                  }
                },
                {
                  name: 'preventOverflow',
                  options: {
                    padding: 20
                  }
                }
              ]
            })
            
            let wordTransAndExplain = await getWordTransAndExplain(this.userLanguage, this.learnLanguage, this.ebookID, sent.sentID, trunk.trunkID, word.wordID, sent.sentTxt, word.word)
            let { wordTrans, wordExplain } = wordTransAndExplain
            this.currentShowWord['trans'] = wordTrans
            this.currentShowWord['explain'] = wordExplain
            
            this.wordTransLoading = false
            popperInstance.update()
          })
        }
      },
      hideTooltip() {
        this.sentTransShow = false
        this.wordTransShow = false
        this.currentShowWord = null
      },
      async refreshWord() {
        this.wordTransLoading = true

        if (this.currentShowWord) {
          let { sentTxt, sentID, trunkID, wordID, word } = this.currentShowWord
          let wordTransAndExplain = await getWordTransAndExplain(this.userLanguage, this.learnLanguage, this.ebookID, sentID, trunkID, wordID, sentTxt, word, 'refresh')
          let { wordTrans, wordExplain } = wordTransAndExplain
          this.currentShowWord['trans'] = wordTrans
          this.currentShowWord['explain'] = wordExplain
        }

        this.wordTransLoading = false
      },
      sendBugReport(bugType) {
        if (this.currentShowWord) {
          let { sentID, trunkID, wordID } = this.currentShowWord
          let bugID = `${this.ebookID}${this.paragraphID}${sentID}${trunkID}${wordID}`
          sendBugReport(this.userLanguage, this.learnLanguage, bugID, bugType)
          this.bugReportWindowShow = false
          this.$message(this.$t('ebook.ebook_feedback_success'))
        }
      },
      setFavWord(favStatus) {
        if (this.userInfo) {
          let uid = this.userInfo.UID
          if (this.currentShowWord) {
            let { favID } = this.currentShowWord
            setFavWord(uid, this.learnLanguage, this.ebookID, favID, favStatus)
          }
        } else {
          this.signIn()
        }
      },
      playWordAudio(type) {
        if (this.currentShowWord) {
          let word = encodeURI(encodeURI(this.currentShowWord.word.toLowerCase()))
          let audioUrl = constants.ebookMediaUrl.replace('LANG', this.learnLanguage) + `read-${this.learnLanguage}-${this.ebookID}-word-${word}.mp3`
          let audioIcon = this.$refs.audio

          if (type && type === 'wordExplain') {
            audioIcon = this.$refs.wordExplainAudio
          }
  
          playAnimAudio(audioUrl, audioIcon)
        }
      },
      playSentAudio(sentIndex) {
        if (this.ifSentUnlock(sentIndex)) {
          clearInterval(this.audioTimer)
          let { sentID } = this.ebookData[sentIndex]
          this.currentAudioSentID = sentID
          this.currentAudioTrunkID = ''
          this.currentAudioWordID = ''

          let audioUrl = constants.ebookMediaUrl.replace('LANG', this.learnLanguage) + `read-${this.learnLanguage}-${this.ebookID}${this.paragraphID}${sentID}.mp3`
          this.audio.src = audioUrl
          this.audio.play()
        } else {
          this.audioPlay = false
        }
      },
      onSentAudioPlay() {
        this.audioPlay = true
        let sent = this.ebookData.find(sent => sent.sentID === this.currentAudioSentID)
        let sentIndex = this.ebookData.findIndex(sent => sent.sentID === this.currentAudioSentID)
        window.scrollTo({ top: this.$refs.sentWrap[sentIndex].offsetTop - 40, behavior: 'smooth' })
        this.audioTimer = setInterval(() => {
          let currentTime = this.audio.currentTime * 1000
          let currentAudioTrunk = sent.trunks.findLast(trunk => currentTime >= trunk.audio_time_1)
          if (currentAudioTrunk) {
            this.currentAudioTrunkID = currentAudioTrunk.trunkID
            // 检测是否需要循环当前trunk
            let endTime = currentAudioTrunk.audio_time_2 || this.audio.duration * 1000
            if (this.audioLoop && currentTime >= endTime - 100) {
              this.resetAudio()
            }
            let currentAudioWord = currentAudioTrunk.words.findLast(word => word.audio_time_1 <= currentTime)
            if (currentAudioWord) {
              this.currentAudioWordID = currentAudioWord.wordID
            }
          }
        }, 50)
      },
      onSentAudioPause() {
        clearInterval(this.audioTimer)
      },
      onSentAudioEnded() {
        clearInterval(this.audioTimer)
        let sentIndex = this.ebookData.findIndex(sent => sent.sentID === this.currentAudioSentID)
        let nextSentIndex = sentIndex + 1
        if (this.ebookData[nextSentIndex]) {
          // 结束自动播放下一句
          this.delayTimer = setTimeout(() => {
            this.playSentAudio(nextSentIndex)
          }, 500)
        }
      },
      playAudio() {
        if (this.audioPlay) {
          this.audioPlay = false
          this.audio.pause()
        } else {
          if (!this.currentAudioSentID) {
            this.playSentAudio(0)
          } else {
            this.audio.play()
          }
        }
      },
      resetAudio() {
        if (this.currentAudioSentID && this.currentAudioTrunkID) {
          let sent = this.ebookData.find(sent => sent.sentID === this.currentAudioSentID)
          let trunk = sent.trunks.find(trunk => trunk.trunkID === this.currentAudioTrunkID)
          this.audio.currentTime = parseInt(trunk.audio_time_1) / 1000
          if (this.audio.pause || this.audio.ended) {
            this.audio.play()
          }
        }
      },
      buyBtnClickHandle() {
        this.$router.push('/pricing')
      },
      buyBtnHandle() {
        if (!this.isMember) {
          let buyBtn = this.$refs.buyBtn[0]
          let buyBtnFixed = this.$refs.buyBtnFixed[0]
          let footerMask = this.$refs.footerMask
          let rect = buyBtn.getBoundingClientRect()
          
          if (rect.top < 0) {
            buyBtnFixed.classList.add('show')
            footerMask.classList.add('show')
          } else {
            buyBtnFixed.classList.remove('show')
            footerMask.classList.remove('show')
          }
        }
      },
      progressHandle: _.debounce(function() {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        let scrollHeight = document.documentElement.scrollHeight
        let clientHeight = document.documentElement.clientHeight

        // 0 - 100
        let progress = Math.round(scrollTop / (scrollHeight - clientHeight) * 100)
        setEbookProgress(this.learnLanguage, this.ebookID, progress)
      }, 500)
    }
  }
</script>

<style lang="scss" scoped>
  $lightGray: #CCCCCC;
  $iconGray: #CACACA;
  $primaryColor: #4388FF;
  $primaryColorDeep: #2D71E7;
  $bgColor: var(--ebook-bg-color);
  $hlColor: var(--ebook-hl-color);
  $textColor: var(--ebook-text-color);
  $fontSize: var(--ebook-font-size);
  $lineHeight: var(--ebook-line-height);

  .basicButton {
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
    border-radius: 40px;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
    transition: all 0.1s;
    &:hover {
      cursor: pointer;
    }
  }

  @mixin commonButton {
    @extend .basicButton;
    background: $primaryColor;
    box-shadow: 0 2px 4px 0 rgba($primaryColor, 0.57);
    &:hover {
      background: $primaryColorDeep;
    }
  }

  @mixin disableButton {
    @extend .basicButton;
    background: $lightGray;
  }

  @mixin cancelButton {
    @extend .basicButton;
    color: $lightGray;
    &:hover {
      background: #EEEEEE;
    }
  }

  .ebookLearn {
    background: $bgColor;
    &.theme_4 {
      .header {
        border-bottom: 1px solid #C5C6D24D;
      }
    }
    .header {
      position: sticky;
      left: 0;
      top: 0;
      width: 100vw;
      height: 80px;
      background: inherit;
      border-bottom: 1px solid #C5C6D2;
      z-index: 100;
      .headerWrap {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        padding: 0 20px;
        width: 100%;
        max-width: 1000px;
        height: 100%;
        box-sizing: border-box;
        .backBtn {
          display: flex;
          cursor: pointer;
          .headerIcon {
            width: 32px;
            height: 32px;
            color: #333333;
          }
        }
        .btnWrap {
          display: flex;
          gap: 20px;
          .btn {
            display: flex;
            align-items: center;
            gap: 5px;
            cursor: pointer;
            &:hover {
              opacity: 0.7;
            }
            .btnIcon {
              width: 32px;
            }
            .btnNum {
              font-weight: 500;
              font-size: 20px;
              color: $primaryColor;
            }
          }
        }
      }
    }
    .content {
      width: 100vw;
      .contentWrap {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        padding: 0 20px;
        padding-bottom: calc(10vh + 60px);
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        .contentMask {
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%;
          background: $bgColor;
          opacity: 0.75;
          z-index: 10;
        }
        .sentWrap {
          margin-top: 40px;
          display: flex;
          align-items: flex-start;
          gap: 5px;
          .playBtn, .transBtn {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            &:hover {
              cursor: pointer;
              opacity: 0.7;
            }
            &.show {
              visibility: hidden;
            }
          }
          .sentIcon {
            width: 26px;
            height: 26px;
            color: #8398AE;
          }
          .sent {
            flex: 1;
            font-size: $fontSize;
            line-height: $lineHeight;
            color: $textColor;
            .trunk {
              padding: 2px 0;
              &.bg {
                background: rgba($primaryColor, 0.17);
                border-radius: 4px;
              }
              .word {
                padding: 2px 0;
                &.underline {
                  text-decoration: underline dashed #979797;
                  text-decoration-thickness: 1px;
                  text-underline-position: under;
                  cursor: pointer;
                }
                &.highlight {
                  color: $hlColor;
                }
                &.bg {
                  background: rgba($primaryColor, 0.21);
                  border-radius: 4px;
                }
              }
            }
          }
        }
        .buyNowWrap {
          margin-top: 40px;
          position: relative;
          display: flex;
          justify-content: center;
          .dashedLine {
            position: absolute;
            top: 50%;
            width: 100%;
            height: 1px;
            background: linear-gradient(
              to left,
              transparent 0%,
              transparent 50%,
              #8398AE 50%,
              #8398AE 100%
            );
            background-size: 16px 1px;
            background-repeat: repeat-x;
          }
          .buyBtn {
            @include commonButton;
            z-index: 20;
            &.fixed {
              display: none;
              &.show {
                display: block;
                position: fixed;
                top: calc(50% - 20px);
              }
            }
          }
        }
      }
    }
    .footer {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100vw;
      height: 10vh;
      min-height: 100px;
      background: inherit;
      border-top: 1px solid #C5C6D2;
      z-index: 100;
      .footerMask {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $bgColor;
        opacity: 0.75;
        z-index: 10px;
        &.show {
          display: block;
        }
      }
      .footerWrap {
        max-width: 1000px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
        .playBtn {
          @include commonButton;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          width: 50px;
          height: 50px;
          border-radius: 50px;
          .btnIcon {
            width: 30px;
            height: 30px;
            color: #FFFFFF;
          }
        }
        .loopBtn, .resetBtn {
          display: flex;
          .footerIcon {
            width: 35px;
            height: 35px;
            color: $primaryColor;
            transition: all 0.1s;
            &.light {
              opacity: 0.5;
            }
            &:hover {
              cursor: pointer;
              color: $primaryColorDeep;
            }
          }
        }
      }
    }
    .tooltip {
      position: relative;
      padding: 12px 20px;
      border-radius: 6px;
      color: #FFFFFF;
      background: #454F60;
      box-shadow: 0 0 12px 0 #00000030;
      z-index: 1000;
      &[data-popper-placement^='top'] > .arrow {
        bottom: -5px;
      }
      &[data-popper-placement^='bottom'] > .arrow {
        top: -5px;
      }
      .arrow, .arrow::before {
        position: absolute;
        width: 10px;
        height: 10px;
        background: inherit;
      }
      .arrow {
        visibility: hidden;
      }
      .arrow::before {
        visibility: visible;
        content: '';
        transform: rotate(45deg);
      }
      .divider {
        margin: 12px 0;
        width: 100%;
        height: 1px;
        background: #FFFFFF0F;
      }
      .refreshBtn {
        position: absolute;
        right: 6px;
        top: 6px;
      }
      .wordTxt {
        font-weight: 900;
        font-size: 14px;
        color: #FFFFFF;
        text-align: center;
      }
      .wordTrans {
        font-weight: 900;
        font-size: 16px;
        color: #FFFFFF73;
        text-align: center;
      }
      .wordIcons {
        display: flex;
        gap: 24px;
        .audioGray {
          width: 18px;
          height: 18px;
          background-size: 54px;
          background-position: -36px 0;
          background-repeat: no-repeat;
          background-image: url('~@/assets/images/ebook/audioGray.png');
          &.active {
            animation: audioGray 0.6s infinite steps(3);
            @keyframes audioGray {
              0% {
                background-position: 0 0;
              }
              100% {
                background-position: -54px 0;
              }
            }
          }
          &:hover {
            opacity: 0.7;
            cursor: pointer;
          }
        }
      }
      .iconWrap {
        display: flex;
        .icon {
          width: 18px;
          height: 18px;
          color: $iconGray;
          &.blue {
            color: $primaryColor;
          }
          &:hover {
            opacity: 0.7;
            cursor: pointer;
          }
        }
      }
    }
  }

  ::v-deep .el-dialog {
    width: 90%;
    max-width: 350px;
    border-radius: 8px;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      font-size: 16px;
      color: #2B2B2B;
      .settingName {
        font-weight: 500;
        font-size: 14px;
      }
    }
    .el-radio-group {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .dialogTitle {
      font-weight: 500;
      font-size: 16px;
      text-align: center;
    }
    &.changeThemeWindow {
      .settingName {
        margin-bottom: 20px;
      }
      .themeWrap {
        display: flex;
        gap: 15px;
        .themeItem {
          width: 60px;
          height: 30px;
          border-radius: 4px;
          box-sizing: border-box;
          transition: all 0.1s;
          &.active {
            border: 2px solid $primaryColor;
          }
          &:hover {
            cursor: pointer;
            border: 2px solid $primaryColor;
          }
        }
      }
    }
    &.settingWindow {
      .divider {
        margin: 20px 0;
        width: 100%;
        height: 1px;
        background: #EEEEEE;
      }
      .settingItem {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .settingWrap {
          width: 124px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .iconWrap {
            display: flex;
            .settingIcon {
              width: 24px;
              height: 24px;
              color: $primaryColor;
              &:hover {
                cursor: pointer;
                opacity: 0.7;
              }
            }
          }
          .settingValue {
            font-weight: 600;
            font-size: 12px;
            color: #585858;
          }
        }
        &.highlightPosSetting {
          margin-bottom: 0;
          justify-content: flex-start;
          gap: 10px;
          .colorBlock {
            width: 18px;
            height: 18px;
            border-radius: 2px;
            background: $primaryColor;
          }
          .dropdownLink {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 3px 8px;
            border-bottom: 1px solid #B8B8B8;
            cursor: pointer;
            .dropdownName {
              font-weight: 500;
              font-size: 14px;
              color: #727272;
            }
            .dropdownArrow {
              width: 20px;
              height: 20px;
              color: #B8B8B8;
            }
          }
          &.disabled {
            opacity: 0.5;
            .dropdownLink {
              cursor: not-allowed;
            }
          }
        }
      }
    }
    &.wordShorthandTipsWindow {
      .dialogWrap {
        text-align: center;
        .tipsContent {
          margin-top: 30px;
          font-weight: 500;
          font-size: 14px;
          text-align: center;
        }
        .confirmBtn {
          @include commonButton;
          margin-top: 30px;
          min-width: 200px;
        }
      }
    }
    &.bugReportWindow {
      .dialogWrap {
        .bugReportRadio {
          margin-top: 30px;
        }
        .btnWrap {
          margin-top: 30px;
          display: flex;
          justify-content: center;
          gap: 20px;
          .cancelBtn {
            @include cancelButton;
            min-width: 120px;
          }
          .confirmBtn {
            @include disableButton;
            min-width: 120px;
            &.active {
              @include commonButton;
            }
          }
        }
      }
    }
    &.wordExplainWindow {
      max-width: 500px;
      .dialogWrap {
        position: relative;
        .audioIcon {
          position: absolute;
          top: 6px;
          right: 0px;
          width: 30px;
          height: 30px;
          background-size: 90px;
          background-position: -60px 3px;
          background-repeat: no-repeat;
          background-image: url('~@/assets/images/ebook/audioBlue.png');
          &:hover {
            cursor: pointer;
            opacity: 0.7;
          }
          &.active {
            animation: audioBlue 0.6s infinite steps(3);
            @keyframes audioBlue {
              0% {
                background-position: 0 3px;
              }
              100% {
                background-position: -90px 3px;
              }
            }
          }
        }
        .wordOriginal {
          font-weight: bold;
          font-size: 30px;
          color: $primaryColor;
        }
        .wordPronunciation {
          margin-top: 20px;
        }
        .wordDefinition {
          margin-top: 20px;
        }
        .wordPosAndTrans {
          margin-top: 20px;
          display: flex;
          align-items: flex-end;
          gap: 10px;
          .wordPos {
            font-style: italic;
            opacity: 0.45;
          }
          .wordTrans {
            font-weight: bold;
            color: #64BCFF;
          }
        }
        .sentExampleWrap, .sentOriginalWrap {
          margin-top: 20px;
          .wrapTitle {
            font-weight: bold;
          }
          .wrapSent {
            margin-top: 5px;
          }
        }
        .backBtn {
          @include commonButton;
          margin-top: 50px;
          min-width: 260px;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  ::v-deep .el-drawer__header {
    display: none;
  }
</style>