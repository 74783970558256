import Vue from 'vue'
import {
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Popover,
  Loading,
  Message,
  Drawer,
  Radio,
  RadioGroup,
  Switch,
  Slider,
  Image,
  Icon,
  Progress
} from 'element-ui'

Vue.use(Dialog)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Popover)
Vue.use(Drawer)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Switch)
Vue.use(Slider)
Vue.use(Image)
Vue.use(Icon)
Vue.use(Progress)
Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$message = Message