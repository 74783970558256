import Vuex from 'vuex'
import checkWrap from '@/components/courses/CheckWrap'
import constants from '@/utils/constants'
import AudioUtil from '@/utils/audioUtil'
import '@/assets/styles/courses/lessonModel.scss'
import '@/assets/styles/courses/grammarTips.scss'

const modelMixins = {
  data() {
    return {
      underline: constants.underline,
      audio: null,
      audioPlaying: false,
      checkStatus: 'default'
    }
  },
  props: ['lessonData', 'lessonIndex', 'seriesCorrectNumber'],
  components: { checkWrap },
  computed: {
    ...Vuex.mapGetters(['courseSettings', 'learnLanguage']),
    lessonVideoUrl() {
      let id = this.lessonData.id
      let type = this.lessonData.kty === '1' ? 's' : 'w'
      let videoURL = constants.courseMediaUrl.replace('LANG', this.learnLanguage) +
        'lesson_f/' +
        constants.langsData[this.learnLanguage]['courseMediaName'] +
        `-f-${type}-${id}.mp4`
      return videoURL
    }
  },
  watch: {
    'audio.playState': function (val) {
      if (val === 'start') {
        this.audioPlaying = true
      } else {
        this.audioPlaying = false
      }
    }
  },
  methods: {
    playAudio(type, id) {
      let audioUrl =
        constants.courseMediaUrl.replace('LANG', this.learnLanguage) +
        'lesson_f/' +
        constants.langsData[this.learnLanguage]['courseMediaName'] +
        `-f-${type}-${id}.mp3`
      this.audio = new AudioUtil([{ audioUrl }])
      this.audio.play()
    },
    playWordAudio(id) {
      let audioUrl =
        constants.courseMediaUrl.replace('LANG', this.learnLanguage) +
        'lesson_f/' +
        constants.langsData[this.learnLanguage]['courseMediaName'] +
        `-f-w-${id}.mp3`
      let audio = new AudioUtil([{ audioUrl }])
      audio.play()
    },
    // 首字母大写
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    correctHandle() {
      this.checkStatus = 'correct'
      this.$emit('correct')
    },
    wrongHandle() {
      this.checkStatus = 'wrong'
      this.$emit('wrong')
    },
    nextHandle() {
      this.audio.stop()
      this.$emit('goNext')
    }
  }
}

export default modelMixins