// 播放音频的同时播放喇叭动画
export function playAnimAudio(audioUrl, target) {
  let audio = document.createElement('audio')
  audio.src = audioUrl
  audio.onplaying = () => {
    target.classList.add('active')
  }
  audio.onended = () => {
    target.classList.remove('active')
  }
  audio.play()
}