import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import '@/assets/styles/reset.scss'
import '@/assets/styles/index.scss'
import '@/assets/fonts/iconfont.css'
import 'animate.css'
import './plugins/element.js'

// firebase
import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyCcjuELMicd2JD7sf5WsP7qSUL2ISWhSPk",
  authDomain: "auth.fluenday.com",
  projectId: "enpal-project",
  storageBucket: "enpal-project.appspot.com",
  messagingSenderId: "539686846998",
  appId: "1:539686846998:web:dd280b66ac5fe3a4692b37",
  measurementId: "G-32EB44D5WT",
  databaseURL: "https://fluenday.firebaseio.com"
}

const DB_core = Object.assign({}, firebaseConfig, {
  databaseURL: "https://enpal-userdata-core.firebaseio.com"
})

const DB_srs = Object.assign({}, firebaseConfig, {
  databaseURL: "https://enpal-userdata-srs.firebaseio.com"
})

const DB_ebook_bookData = Object.assign({}, firebaseConfig, {
  databaseURL: "https://fluenday-ebook-bookdata-v3.firebaseio.com/"
})

const DB_ebook_userData = Object.assign({}, firebaseConfig, {
  databaseURL: "https://enpal-ebook-userdata.firebaseio.com/"
})

// init
const app = initializeApp(firebaseConfig)
const app_db_core = initializeApp(DB_core, 'app_db_core')
const app_db_srs = initializeApp(DB_srs, 'app_db_srs')
const app_db_ebook_bookData = initializeApp(DB_ebook_bookData, 'app_db_ebook_bookData')
const app_db_ebook_userData = initializeApp(DB_ebook_userData, 'app_db_ebook_userData')

// auth
const auth = getAuth(app)
const auth_core = getAuth(app_db_core)
const auth_srs = getAuth(app_db_srs)
const auth_ebook = getAuth(app_db_ebook_userData)

// db
const db_core = getDatabase(app_db_core)
const db_srs = getDatabase(app_db_srs)
const db_ebook_bookData = getDatabase(app_db_ebook_bookData)
const db_ebook_userData = getDatabase(app_db_ebook_userData)

Vue.prototype.auth = auth
Vue.prototype.auth_core = auth_core
Vue.prototype.auth_srs = auth_srs
Vue.prototype.auth_ebook = auth_ebook

Vue.prototype.db_core = db_core
Vue.prototype.db_srs = db_srs
Vue.prototype.db_ebook_bookData = db_ebook_bookData
Vue.prototype.db_ebook_userData = db_ebook_userData

window.auth = auth
window.auth_core = auth_core
window.auth_srs = auth_srs
window.auth_ebook = auth_ebook

window.db_core = db_core
window.db_srs = db_srs
window.db_ebook_bookData = db_ebook_bookData
window.db_ebook_userData = db_ebook_userData

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
