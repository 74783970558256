<template>
  <div class="signInWindow">
    <el-dialog
      :title="$t('main.sign_in_sign_up')"
      :visible="ifShow"
      :modal-append-to-body="false"
      @open="openDialog"
      @close="closeDialog"
      center>
      <div id="firebaseui-auth-container" v-loading="dialogLoading"></div>
    </el-dialog>
  </div>
</template>

<script>
  import firebase from 'firebase/compat/app';
  import * as firebaseui from 'firebaseui'
  import 'firebaseui/dist/firebaseui.css'
  import { getUserToken } from '@/api/auth'
  import { setToken } from '@/utils/authUtil'

  export default {
    data() {
      return {
        dialogLoading: false,
        uiLoad: false
      }
    },
    props: ['ifShow'],
    methods: {
      openDialog() {
        this.$nextTick(() => {
          if (!this.uiLoad) this.initFirebase()
        })
      },
      closeDialog() {
        this.$emit('update:ifShow', false)
      },
      initFirebase() {
        this.dialogLoading = true
        this.uiLoad = true
        let that = this
        let uiConfig = {
          callbacks: {
            signInSuccessWithAuthResult: function (authResult) {
              that.dialogLoading = true
              getUserToken(authResult).then((token) => {
                that.dialogLoading = false
                setToken(token)
                location.reload()
              })
            },
            uiShown: function() {
              that.dialogLoading = false
            }
          },
          signInFlow: 'popup',
          signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            'apple.com'
          ],
          tosUrl: 'https://www.fluenday.com/terms-conditions.html',
          privacyPolicyUrl: function() {
            window.location.assign('https://www.fluenday.com/privacy-policy.html')
          }
        }

        let ui = new firebaseui.auth.AuthUI(this.auth)
        ui.start('#firebaseui-auth-container', uiConfig)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .signInWindow {
    ::v-deep .el-dialog {
      max-width: 400px;
    }
    ::v-deep .v-modal {
      opacity: 0.92;
    }
  }

  @media screen and (max-width: 768px) {
    .signInWindow {
      ::v-deep .el-dialog {
        width: 90%;
      }
    }
  }
</style>