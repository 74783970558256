<template>
  <div class="speakingFollow">
    <template v-if="finish">
      <finish class="animate__animated animate__fadeIn"></finish>
    </template>

    <template v-else>
      <div class="lessonHeader">
        <div class="close" @click="closeClickHandle">
          <img src="@/assets/images/clips/lesson/close.png" alt="close">
        </div>

        <div class="controlWrap">
          <div class="difficulty" @click="difficultyClickHandle">{{$t(`course.role_play_${speakingDifficulty}`)}}</div>

          <div class="play" @click="playClickHandle">
            <img src="@/assets/images/courses/play.png" alt="play">
          </div>

          <el-popover
            placement="bottom-end"
            width="240"
            trigger="click">
            <div class="settingsWrap">
              <div class="settingItem">
                <div class="settingName">{{$t('course.show_translator')}}</div>
                <el-switch
                  v-model="showTrans"
                  active-color="#DCE4F2">
                </el-switch>
              </div>
              <div class="settingItem">
                <div class="settingName">{{$t('course.sound_speed')}}</div>
                <div class="playBackRateWrap">
                  <div class="playBackRateVal">{{(playBackRate * 100).toFixed() + '%'}}</div>
                  <el-slider
                    v-model="playBackRate"
                    :min="0.5"
                    :max="1.5"
                    :step="0.1"
                    :show-tooltip="false">
                  </el-slider>
                </div>
              </div>
            </div>
            <div class="settings" slot="reference">
              <img src="@/assets/images/courses/setting.png" alt="settings">
            </div>
          </el-popover>
        </div>
      </div>

      <div class="lessonMain">
        <div class="lessonWrap">
          <template v-for="(dialogue, i) in dialogues">
            <speaking-model
              :key="i"
              :dialogueData="dialogue"
              :audioIndex="i"
              :audio="audio"
              :showTrans="showTrans"
              :difficulty="speakingDifficulty">
            </speaking-model>
          </template>
        </div>
      </div>

      <template v-if="finishPanel">
        <div class="finishPanel">
          <div class="doneBtn" @click="doneHandle">{{$t('course.test_finish')}}</div>
          <div class="roleplayBtn" @click="roleplayHandle">{{$t('course.role_play_practice')}}</div>
        </div>
      </template>

      <template v-else>
        <div v-show="recording" class="recordingWrap" @click="endRecord">
          <div class="recordingText">{{$t('main.tap_here_when_you_re_done')}}</div>
          <canvas id="waveform" width="400"></canvas>
        </div>

        <div v-show="!recording" class="recordWrap">
          <div class="listenBtn" :class="{active: listenActive}" @click.stop="listenHandle">
            <svg t="1683271887690" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15099" width="32" height="32"><path d="M113.757091 522.984727a398.242909 398.242909 0 0 1 796.485818 0v284.439273c0 31.418182-25.506909 56.925091-56.925091 56.925091h-56.878545a56.878545 56.878545 0 0 1-56.878546-56.925091V579.956364c0-31.418182 25.460364-56.925091 56.878546-56.925091h56.878545a341.317818 341.317818 0 0 0-682.635636 0h56.878545c31.418182 0 56.878545 25.506909 56.878546 56.925091v227.514181c0 31.418182-25.460364 56.925091-56.878546 56.925091H170.682182a56.878545 56.878545 0 0 1-56.925091-56.925091v-284.392727z" fill="#ffffff" p-id="15100"></path></svg>
          </div>
          <div class="recordBtn" :class="{active: recordActive}" @click.stop="recordHandle">
            <svg t="1681807791161" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4310" id="mx_n_1681807791162" width="40" height="40"><path d="M512 653.553c126.779 0 229.553-102.773 229.553-229.553V229.553C741.553 102.774 638.779 0 512 0S282.447 102.774 282.447 229.553V424c0 126.779 102.774 229.553 229.553 229.553z" p-id="4311" fill="#ffffff"></path><path d="M862 424v-50h-80.418v50c0 148.886-120.696 269.582-269.582 269.582S242.418 572.886 242.418 424v-50H162v50c0 179.702 135.429 327.771 309.791 347.715v171.867H212V1024h600v-80.418H552.209V771.715C726.571 751.771 862 603.702 862 424z" p-id="4312" fill="#ffffff"></path></svg>
          </div>
          <div class="nextBtn" :class="{active: nextActive}" @click.stop="nextHandle">
            <svg t="1683272531317" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="19207" width="32" height="32"><path d="M384 883.08c-8.19 0-16.38-3.12-22.62-9.38-12.5-12.5-12.5-32.75 0-45.25L677.83 512 361.38 195.55c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L734.4 478.07c18.72 18.72 18.72 49.16 0 67.88L406.62 873.7c-6.24 6.25-14.43 9.38-22.62 9.38z m305.14-359.77h0.31-0.31z" fill="#004E94" p-id="19208"></path></svg>
          </div>
        </div>
      </template>

      <close-confirm :maskShow="maskShow" @cancel="maskShow=false"></close-confirm>

      <el-drawer
        :visible.sync="dialogueDrawer"
        :destroy-on-close="true"
        @close="speakingDialogueUnwatch()"
        size="90%"
        direction="btt">
        <div class="dialogueDrawer">
          <template v-for="(dialogue, i) in dialoguesData">
            <speaking-dialogue
              :key="i"
              :dialogueData="dialogue"
              :audioIndex="i"
              :audio="audio">
            </speaking-dialogue>
          </template>
        </div>
      </el-drawer>
    </template>
  </div>
</template>

<script>
  import Vuex from 'vuex'
  import Recorder from '@/utils/recorderUtil'
  import { updateHistoryData, updateDialogueProgressData } from '@/utils/dataUpdateUtil'
  import speakingModel from '@/components/courses/speaking/SpeakingModel.vue'
  import speakingDialogue from '@/components/courses/speaking/SpeakingDialogue.vue'
  import finish from '@/components/courses/DialogueFinish.vue'
  import closeConfirm from '@/components/CloseConfirmWindow.vue'
  import learnTimeMixins from '@/mixins/learnTimeMixins'

  export default {
    mixins: [ learnTimeMixins ],
    data() {
      return {
        dialogues: [],
        dialogueIndex: 0,
        dialogueDrawer: false,
        listenActive: false,
        recordActive: false,
        nextActive: false,
        maskShow: false,
        finish: false,
        finishPanel: false,
        recording: false,
        recorder: null,
        drawRecordId: null,
        angle: 0,
        canvas: null,
        ctx: null,
        speakingDialogueUnwatch: null,
      }
    },
    props: ['dialoguesData', 'audio', 'coursesDialogueInfo'],
    components: {
      speakingModel,
      speakingDialogue,
      finish,
      closeConfirm
    },
    computed: {
      ...Vuex.mapGetters(['courseSettings']),
      speakingDifficulty: {
        get() {
          return this.courseSettings.speakingDifficulty
        },
        set(val) {
          let courseSettings = this.courseSettings
          courseSettings.speakingDifficulty = val
          this.$store.commit('set_courseSettings', courseSettings)
        }
      },
      showTrans: {
        get() {
          return this.courseSettings.showTrans
        },
        set(val) {
          let courseSettings = this.courseSettings
          courseSettings.showTrans = val
          this.$store.commit('set_courseSettings', courseSettings)
        }
      },
      playBackRate: {
        get() {
          return this.courseSettings.playBackRate
        },
        set(val) {
          let courseSettings = this.courseSettings
          courseSettings.playBackRate = val
          this.$store.commit('set_courseSettings', courseSettings)
        }
      }
    },
    watch: {
      dialogueIndex(index) {
        this.$nextTick(function() {
          // 让页面始终在最底部
          let scrollArea = document.querySelector('.lessonMain')
          scrollArea.scrollTop = scrollArea.scrollHeight
        })

        if (this.dialoguesData.length) {
          if (index < this.dialoguesData.length) {
            this.init()
          } else {
            this.finishPanel = true
            this.timingStop()
            this.updateData()
          }
        }
      },
      'audio.playState': function(val) {
        if (val === 'end' && !this.nextActive) {
          this.recordActive = true
          this.recordHandle()
        }
      }
    },
    mounted() {
      this.init()
      this.initRecorder()
      this.timingStart()
    },
    methods: {
      init() {
        let currentDialogue = this.dialoguesData[this.dialogueIndex]
        this.dialogues.push(currentDialogue)
      },
      initRecorder() {
        this.recorder = Recorder
        this.canvas = document.getElementById('waveform')
        this.ctx = this.canvas.getContext('2d')
      },
      closeClickHandle() {
        if (this.dialogueIndex > 0) {
          this.maskShow = true
        } else {
          this.$router.push('/courses')
        }
      },
      difficultyClickHandle() {
        if (this.speakingDifficulty === 'easy') {
          this.speakingDifficulty = 'medium'
        } else if (this.speakingDifficulty === 'medium') {
          this.speakingDifficulty = 'hard'
        } else if (this.speakingDifficulty === 'hard') {
          this.speakingDifficulty = 'easy'
        }
      },
      playClickHandle() {
        this.dialogueDrawer = true
        this.$nextTick(() => {
          let dialogDoms = document.querySelectorAll('.speakingDialogue')
          let playIndex = 0
          this.speakingDialogueUnwatch = this.$watch('audio.playState', function(val) {
            if (val === 'end') {
              if (playIndex < this.dialoguesData.length) {
                setTimeout(() => {
                  dialogDoms[playIndex].scrollIntoView({behavior: 'smooth'})
                  this.audio.play(playIndex)
                  playIndex ++
                }, 500)
              } else {
                this.speakingDialogueUnwatch()
              }
            } 
          }, { immediate: true })
        })
      },
      listenHandle() {
        if (this.listenActive) {
          this.recorder.play()
        }
      },
      recordHandle() {
        if (this.recordActive) {
          this.recording = true
          this.recorder.start(this)
          this.drawRecord()
        }
      },
      nextHandle() {
        this.listenActive = false
        this.recordActive = false
        this.nextActive = false
        this.dialogueIndex ++
      },
      doneHandle() {
        this.finish = true
      },
      roleplayHandle() {
        this.$router.push('/courses/speaking/roleplay')
      },
      endRecord() {
        this.recording = false
        this.recorder.stop()
        this.drawRecordId && cancelAnimationFrame(this.drawRecordId)
        this.drawRecordId = null

        this.listenActive = true
        this.nextActive = true
      },
      drawRecord() {
        let canvas = this.canvas
        let ctx = this.ctx

        // 实时获取音频大小数据
        let dataArray = this.recorder.getRecordAnalyseData()
        let bufferLength = dataArray.length

        // 清空画布
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        // 填充背景色
        ctx.fillStyle = '#F3F4F8'
        ctx.fillRect(0, 0, canvas.width, canvas.height)

        // 绘制竖条状图形
        let barWidth = (canvas.width / bufferLength) * 2.5
        let x = 0

        for (let i = 0; i < bufferLength; i++) {
          let barHeight = dataArray[i] * 0.1

          // 添加默认运动效果
          let yOffset = Math.sin(this.angle + i * 0.5) * 10
          barHeight += yOffset

          ctx.fillStyle = 'rgb(0, 78, ' + (barHeight + 100) + ')'
          ctx.fillRect(x, (canvas.height - barHeight) / 2, barWidth, barHeight)

          x += barWidth + 1
        }

        // 更新动画参数
        this.angle += 0.05

        // 用requestAnimationFrame稳定60fps绘制
        this.drawRecordId = requestAnimationFrame(this.drawRecord)
      },
      updateData() {
        updateHistoryData(this.duration, 5)
        updateDialogueProgressData(this.coursesDialogueInfo)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .speakingFollow {
    .lessonHeader {
      margin: 0 auto;
      padding-top: 10px;
      height: 30px;
      width: 90vw;
      max-width: 960px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      .close, .play, .settings, .difficulty {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        cursor: pointer;
      }
      .close {
        img {
          width: 17px;
        }
      }
      .play, .settings {
        img {
          width: 22px;
        }
      }
      .difficulty {
        font-weight: 700;
        font-size: 16px;
        color: #A6A7C1;
      }
      .controlWrap {
        display: flex;
      }
    }
    .lessonMain {
      position: relative;
      width: 100%;
      height: calc(100vh - 30px);
      overflow-x: hidden;
      overflow-y: auto;
      box-sizing: border-box;
      &::-webkit-scrollbar {
        width: 0;
      }
      .lessonWrap {
        margin: 0 auto;
        padding: 20px 20px 180px;
        max-width: 600px;
        .tips {
          padding: 20px 0;
          text-align: center;
          font-weight: 400;
          font-size: 14px;
          color: #2F2F4D;
        }
      }
    }
    .finishPanel {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      .doneBtn, .roleplayBtn {
        min-width: 230px;
        height: 42px;
        line-height: 42px;
        border-radius: 42px;
        border: 2px solid #004E94;
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s;
      }
      .doneBtn {
        margin-right: 60px;
        background: #FFFFFF;
        color: #004E94;
        &:hover {
          color: #0A4172;
          border: 2px solid #0A4172;
        }
      }
      .roleplayBtn {
        background: #004E94;
        color: #FFFFFF;
        &:hover {
          background: #0A4172;
          border: 2px solid #0A4172;
        }
      }
    }
    .recordingWrap {
      position: fixed;
      bottom: 0;
      width: 100%;
      background: #F3F4F8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .recordingText {
        font-weight: 400;
        font-size: 18px;
        color: #9090A2;
      }
    }
    .recordWrap {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      .listenBtn, .recordBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #CBD8EE;
        &.active {
          background: #004E94;
          cursor: pointer;
          transition: all 0.3s;
          &:hover {
            background: #0A4172;
          }
        }
      }
      .listenBtn {
        width: 52px;
        height: 52px;
        border-radius: 52px;
      }
      .recordBtn {
        margin: 0 50px;
        width: 68px;
        height: 68px;
        border-radius: 68px;
      }
      .nextBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 52px;
        height: 52px;
        border-radius: 52px;
        border: 2px solid #004E94;
        background: transparent;
        box-sizing: border-box;
        visibility: hidden;
        cursor: pointer;
        &.active {
          visibility: visible;
        }
        &:hover {
          border: 2px solid #0A4172;
          svg {
            path {
              fill: #0A4172;
            }
          }
        }
      }
    }
    .dialogueDrawer {
      margin: 0 auto;
      padding: 20px 20px 100px;
      max-width: 600px;
      box-sizing: content-box;
    }
  }

  @media screen and (max-width: 768px) {
    .speakingFollow {
      .finishPanel {
        flex-direction: column;
        .doneBtn {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
</style>

<style lang="scss">
  .settingsWrap {
    padding: 8px;
    .settingItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 50px;
      .settingName {
        font-weight: 400;
        font-size: 14px;
        color: #2F2F4D;
      }
      .playBackRateWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .playBackRateVal {
          margin-right: 8px;
          font-weight: 400;
          font-size: 12px;
          color: #004E94;
        }
        .el-slider {
          width: 80px;
        }
        .el-slider__runway {
          background: #DCE4F2;
        }
        .el-slider__bar {
          background: #004E94;
        }
        .el-slider__button {
          width: 10px;
          height: 10px;
          background: #004E94;
          border: 1px solid #FFFFFF;
        }
      }
    }
  }

  .el-switch .el-switch__core:after {
    background: #004E94;
  }
</style>