<template>
  <div class="wordFavList">
    <div class="header">
      <div class="headerWrap">
        <div class="headerBtn" @click="$emit('hideWindow')">
          <Icon icon="material-symbols:arrow-back-ios-new-rounded" class="headerIcon" />
        </div>
        <div class="headerTitle">{{$t('ebook.word_list')}}</div>
      </div>
    </div>

    <div class="content">
      <div class="contentWrap">
        <template v-for="(word, i) in favWords">
          <div class="wordWrap" @click="playAudio(word, i)" :key="i">
            <div class="audioIcon" ref="audio"></div>
            <div class="word">{{word.word}}</div>
            <div class="trans">{{word.trans}}</div>
            <div v-if="word.fav_status" class="iconWrap" @click.stop="setFavStatus(word, false)">
              <Icon icon="streamline:star-1-solid" class="starIcon blue" />
            </div>
            <div v-else class="iconWrap" @click.stop="setFavStatus(word, true)">
              <Icon icon="streamline:star-1" class="starIcon" />
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import Vuex from 'vuex'
  import { Icon } from '@iconify/vue2'
  import constants from '@/utils/constants'
  import { playAnimAudio } from '@/utils/commonUtil'
  import { setFavWord } from '@/api/ebook'

  export default {
    props: ['favWords'],
    computed: {
      ...Vuex.mapGetters(['userInfo', 'learnLanguage'])
    },
    components: {
      Icon
    },
    methods: {
      setFavStatus(word, status) {
        word.fav_status = status
        if (this.userInfo) {
          let { favID } = word
          let ebookID = favID.slice(0, 3)
          let uid = this.userInfo.UID
          setFavWord(uid, this.learnLanguage, ebookID, favID, status)
        }
      },
      playAudio(wordData, index) {
        let { favID, word } = wordData
        let ebookID = favID.slice(0, 3)
        let encodeWord = encodeURI(encodeURI(word))
        let audioUrl = constants.ebookMediaUrl.replace('LANG', this.learnLanguage) + `read-${this.learnLanguage}-${ebookID}-word-${encodeWord}.mp3`
        let audioIcon = this.$refs.audio[index]

        playAnimAudio(audioUrl, audioIcon)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .wordFavList {
    width: 100%;
    height: 100vh;
    background: #EEEEEE;
    .header {
      width: 100%;
      height: 80px;
      background: inherit;
      border-bottom: 1px solid #8398AE;
      .headerWrap {
        position: relative;
        display: flex;
        align-items: center;
        margin: 0 auto;
        padding: 0 20px;
        width: 100%;
        max-width: 1000px;
        height: 100%;
        box-sizing: border-box;
        .headerBtn {
          display: flex;
          &:hover {
            cursor: pointer;
            opacity: 0.7;
          }
          .headerIcon {
            width: 32px;
            height: 32px;
            color: #333333;
          }
        }
        .headerTitle {
          font-weight: 500;
          font-size: 24px;
          color: #333333;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .content {
      height: calc(100% - 81px);
      background: #8398AE33;
      overflow: auto;
      padding: 20px;
      box-sizing: border-box;
      .contentWrap {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        border-radius: 10px;
        overflow: hidden;
        background: #EEEEEE;
        box-shadow: 0 2px 4px #0000000D;
        box-sizing: border-box;
        .wordWrap {
          padding: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          color: #333333;
          border-bottom: 1px solid #CCCCCC;
          transition: all 0.1s;
          &:last-child {
            border-bottom: none;
          }
          &:hover {
            cursor: pointer;
            background: #0000000D;
          }
          .audioIcon {
            width: 18px;
            height: 18px;
            background-size: 54px;
            background-position: -36px 2px;
            background-repeat: no-repeat;
            background-image: url('~@/assets/images/ebook/audioBlue.png');
            &.active {
              animation: audioBlue 0.6s infinite steps(3);
              @keyframes audioBlue {
                0% {
                  background-position: 0 2px;
                }
                100% {
                  background-position: -54px 2px;
                }
              }
            }
          }
          .iconWrap {
            display: flex;
            .starIcon {
              width: 18px;
              height: 18px;
              color: #CACACA;
              &.blue {
                color: #4388FF;
              }
              &:hover {
                opacity: 0.7;
              }
            }
          }
          .word {
            font-size: 16px;
            font-weight: bold;
            flex: 1;
          }
          .trans {
            font-size: 14px;
            opacity: 0.55;
          }
        }
      }
    }
  }
</style>