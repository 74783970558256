const dataSourceUrl = 'https://www.fluenday.com/dataSource'
const mediaSourceUrl = 'https://d5jzww2qenbcc.cloudfront.net'
const apiUrl = 'https://webapi.fluenday.com'
const domain = 'https://www.fluenday.com'

export default {
  domain,
  // variables
  testMode: false,
  testUserInfo: { "UID": "EnPalFB1667465570771117421", "UID_JWT": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiJFblBhbEZCMTY2NzQ2NTU3MDc3MTExNzQyMSIsImlzcyI6ImZpcmViYXNlLWFkbWluc2RrLWxuZ2hnQGVucGFsLXByb2plY3QuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJzdWIiOiJmaXJlYmFzZS1hZG1pbnNkay1sbmdoZ0BlbnBhbC1wcm9qZWN0LmlhbS5nc2VydmljZWFjY291bnQuY29tIiwiYXVkIjoiaHR0cHM6Ly9pZGVudGl0eXRvb2xraXQuZ29vZ2xlYXBpcy5jb20vZ29vZ2xlLmlkZW50aXR5LmlkZW50aXR5dG9vbGtpdC52MS5JZGVudGl0eVRvb2xraXQiLCJleHAiOjE2ODQ3Mzk3MjMsImlhdCI6MTY4NDczNjEyM30.YmTI7x2VnTnyWkhV3HmyvTjyJ9Oh8x9dDca-WG81W__f6e91bDm49tWuR-8waoUKwwIaOfu36u3iGjfKr67WM6Rd0IhK1E3qrIKJ-ZvhcC9TDhOE1D4SrZ4HmKAHnh9uZjKjUU8AmecvrkcLj5mWrlQtZyWMtw-hpHxz1utikL6fmBtQav5hgAL_k0MTRF825nN7p1QxwZPAxW9VaRq0vc6AxibWHAbLTB9aqd2_c75niVC7yYVGHbiB2rLQIfYvVPt2esyDQjyrVBlFSBXTZzee2q4eh4NJkvxfm0PUJUedbGplOLywnSqUvuEbp4R9RGqRgOpNs1J4jYLUEjkKww", "NickName": "641494970", "FullName": "", "FromType": "em", "UVersion": "ios-2.0.5", "Email": "641494970@qq.com", "Main": "", "StatusJSON": "", "TokenId": "1667465570771117421", "SynKey": "7s78SUK15AE+OikFXjJDDg==" },

  // constants
  underline: '_____',
  coursesDialoguePrefix: ['P:', 'M:', 'H:', 'H : ', 'F:', 'F : ', 'F1:', 'F&M:'],
  allPuncRegex: /[`:_\.~!@#$%^&*()\+=<>?"{}|,\/;'\\[\]·~！@#￥%……&*（）——\+={}|《》？：“”【】、；‘’，。、]/g,

  // langs
  baseLangs: ['en', 'cn', 'jp', 'kr', 'es', 'fr', 'de', 'pt', 'it', 'vt', 'ru', 'id', 'thai', 'tr'],
  learnLangs: ['en', 'es', 'fr'],
  langsData: {
    'en': {
      fullName: 'English',
      englishName: 'English',
      htmlLang: 'en',
      ebookLang: 'eng',
      courseMediaName: 'enes',
      clipsMediaName: 'enpal',
      courseTrans: ['cn', 'jp', 'kr', 'es', 'fr', 'de', 'pt', 'vt', 'ru', 'tr'],
      clipsTrans: ['cn', 'jp', 'kr', 'es', 'fr', 'de', 'pt', 'it', 'vt', 'ru', 'id', 'thai', 'tr']
    },
    'cn': {
      fullName: '繁體中文',
      englishName: 'Traditional Chinese',
      htmlLang: 'zh',
      ebookLang: 'tchn'
    },
    'jp': {
      fullName: '日本語',
      englishName: 'Japanese',
      htmlLang: 'ja',
      ebookLang: 'jpn'
    },
    'kr': {
      fullName: '한국어',
      englishName: 'Korean',
      htmlLang: 'ko',
      ebookLang: 'krn'
    },
    'es': {
      fullName: 'Español',
      englishName: 'Spanish',
      htmlLang: 'es',
      ebookLang: 'spn',
      courseMediaName: 'esus',
      clipsMediaName: 'fdes',
      courseTrans: ['en', 'cn', 'jp', 'fr', 'de'],
      clipsTrans: ['en', 'cn', 'jp', 'kr', 'fr', 'de']
    },
    'fr': {
      fullName: 'Français',
      englishName: 'French',
      htmlLang: 'fr',
      ebookLang: 'frn',
      courseMediaName: 'frus',
      clipsMediaName: 'fdfr',
      courseTrans: ['en', 'cn', 'de'],
      clipsTrans: ['en', 'cn', 'jp', 'es', 'de', 'tr']
    },
    'de': {
      fullName: 'Deutsch',
      englishName: 'German',
      htmlLang: 'de',
      ebookLang: 'gen',
      courseMediaName: 'deus',
      clipsMediaName: 'fdde',
      courseTrans: ['en', 'cn', 'jp', 'es', 'fr'],
      clipsTrans: ['en', 'cn', 'jp', 'es', 'fr', 'tr']
    },
    'pt': {
      fullName: 'Português',
      englishName: 'Portuguese',
      htmlLang: 'pt',
      ebookLang: 'ptg'
    },
    'it': {
      fullName: 'Italiano',
      englishName: 'Italian',
      htmlLang: 'it',
      ebookLang: 'itn'
    },
    'vt': {
      fullName: 'Tiếng Việt',
      englishName: 'Vietnamese',
      htmlLang: 'vi',
      ebookLang: 'vtn'
    },
    'ru': {
      fullName: 'Русский',
      englishName: 'Russian',
      htmlLang: 'ru',
      ebookLang: 'rus'
    },
    'id': {
      fullName: 'Bahasa Indonesia',
      englishName: 'Indonesian',
      htmlLang: 'id',
      ebookLang: 'idn'
    },
    'thai': {
      fullName: 'ไทย',
      englishName: 'Thai',
      htmlLang: 'th',
      ebookLang: 'thai'
    },
    'tr': {
      fullName: 'Türkçe',
      englishName: 'Turkish',
      htmlLang: 'tr',
      ebookLang: 'tur'
    },
  },
  i18nMap: {
    "en": "en",
    "zh": "cn",
    "zh-CN": "cn",
    "zh-TW": "cn",
    "ja": "jp",
    "ko": "kr",
    "es": "es",
    "fr": "fr",
    "de": "de",
    "pt": "pt",
    "it": "it",
    "vi": "vt",
    "ru": "ru",
    "id": "id",
    "th": "thai",
    "tr": "tr"
  },

  // settings
  courseDefaultSettings: {
    soundEffect: true,
    showTrans: true,
    playBackRate: 1,
    speakingDifficulty: 'easy'
  },
  ebookDefaultSettings: {
    theme: 1,  // 1 / 2 / 3 / 4
    fontSize: 1,  // 0.5 - 1.5
    playSpeed: 1,  // 0.5 - 1.5
    highlight: false,  // true / false
    highlightPos: 'JJ'  // JJ / NN / RB / VB
  },

  // data source
  clipsUnitsSourceUrl: dataSourceUrl + `/clips/LEARNLANG/USERLANG/units/units.json`,
  clipsLessonSourceUrl: dataSourceUrl + '/clips/LEARNLANG/USERLANG/lessons/LESSONID.json',
  courseUnitsSourceUrl: dataSourceUrl + '/course/LEARNLANG/units/units.json',
  courseLessonSourceUrl: dataSourceUrl + '/course/LEARNLANG/lessons/lesson_LESSONID/USERLANG.json',
  courseGrammarSourceUrl: dataSourceUrl + '/course/LEARNLANG/grammarTip/GRAMMARID/USERLANG.html',

  // media source
  clipsMediaUrl: mediaSourceUrl + '/LANG/main/movie/',
  courseMediaUrl: mediaSourceUrl + '/LANG/main/course/',
  ebookMediaUrl: mediaSourceUrl + '/LANG/main/read/',
  correctAudioUrl: mediaSourceUrl + '/others/soundEffects/correct.mp3',
  wrongAudioUrl: mediaSourceUrl + '/others/soundEffects/wrong.mp3',

  // apis
  plansApi: apiUrl + '/v1/paypal_subscription_plans',
  memberStatusApi: apiUrl + '/v1/purchase_memberstatus',
  bigFansStatusApi: apiUrl + '/v1/user_check_bigfans',
  facebookConvertApi: apiUrl + '/v1/facebook_converter_api',
  userRemoveProfile: apiUrl + '/v1/user_remove_profile',
  chatCompletionsApi: apiUrl + '/v1/openai_chatcompletions',

  // auth apis
  initFirebase: apiUrl + '/passport/user_initfb',
  userVerify: apiUrl + '/passport/user_verify',
  userSignOut: apiUrl + '/passport/user_signout',
  validateToken: apiUrl + '/passport/validate_token',

  alphabetSourceUrl: 'https://d27hu3tsvatwlt.cloudfront.net/mfsource/LANG/z/alpha_f/LANG-f-zy-FILENAME.mp3'
}