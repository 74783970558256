<template>
  <div class="wordShorthand">
    <div class="header">
      <div class="headerWrap">
        <div class="headerBtn" @click="$emit('hideWindow')">
          <Icon icon="material-symbols:arrow-back-ios-new-rounded" class="headerIcon" />
        </div>
        <div class="headerTitle">{{headerTitle}}</div>
        <div class="headerBtn" @click="favWordListWindowShow = true">
          <Icon icon="material-symbols:format-list-bulleted" class="headerIcon" />
        </div>
      </div>
    </div>

    <div class="content">
      <div class="contentWrap" v-if="currentWord">
        <div v-if="mode === 'shorthand'" class="wordCardWrap">
          <transition
            :enter-active-class="`animate__animated animate__faster animate__${cardAnimEnterClass}`"
            :leave-active-class="`animate__animated animate__faster animate__${cardAnimLeaveClass}`">
            <div class="wordCard" v-show="animStatus">
              <div class="audioIcon" ref="audio" @click="playAudio(currentWord)"></div>
              <div class="wordWrap">
                <div class="word">{{currentWord.word}}</div>
              </div>
              <transition
                enter-active-class="animate__animated animate__faster animate__fadeInUp"
                leave-active-class="animate__animated animate__faster animate__fadeOutDown">
                <div class="transWrap" v-show="transShow">
                  <div class="trans">{{currentWord.trans}}</div>
                  <div class="divider"></div>
                </div>
              </transition>
              <div class="btnWrap">
                <div v-if="transShow" class="transBtn" @click="transShow = false">{{$t('ebook.hide_translation')}}</div>
                <div v-else class="transBtn" @click="transShow = true">{{$t('ebook.show_translation')}}</div>
              </div>
            </div>
          </transition>
          <div class="wordIndexWrap">
            <div class="changeIndexBtn" @click="changeWordIndex(-1)">
              <Icon icon="material-symbols:arrow-back-ios-new-rounded" class="arrowIcon" />
            </div>
            <div class="wordIndex">{{`${activeWordCard}/${choosedNum}`}}</div>
            <div class="changeIndexBtn" @click="changeWordIndex(1)">
              <Icon icon="material-symbols:arrow-forward-ios-rounded" class="arrowIcon" />
            </div>
          </div>
          <div v-show="activeWordCard === choosedNum" class="continueBtn" @click="changeMode('transSelect')">{{$t('web.continue')}}</div>
        </div>

        <div v-else-if="mode === 'transSelect'" class="transSelectWrap">
          <transition
            enter-active-class="animate__animated animate__faster animate__fadeInRight"
            leave-active-class="animate__animated animate__faster animate__fadeOutLeft">
            <div class="questionWrap" v-show="animStatus">
              <div class="word">{{currentWord.word}}</div>
              <div class="transOptions">
                <template v-for="(word, i) in transOptions">
                  <div class="transOption" ref="op" @click="optionChoosed(word, i)" :key="i">{{word.trans}}</div>
                </template>
              </div>
            </div>
          </transition>
          <div class="wordIndex">{{`${activeWordCard}/${choosedNum}`}}</div>
        </div>

        <div v-else class="transPairWrap">
          <div class="topWrap">
            <template v-for="(word, i) in pairList">
              <transition enter-active-class="animate__animated animate__faster animate__fadeIn" :key="i">
                <div v-show="word.show" class="pair block right" ref="pair" :key="i">
                  <div class="word">{{word.word}}</div>
                  <div class="trans">{{word.trans}}</div>
                </div>
              </transition>
            </template>
          </div>
          <div class="bottomWrap">
            <div class="wordsWrap">
              <template v-for="(word, i) in pairWordList">
                <transition leave-active-class="animate__animated animate__faster animate__fadeOut" :key="i">
                  <div v-show="word.show" class="word block" ref="pairWord" @click="pairOptionChoosed('word', word, i)" :key="i">{{word.word}}</div>
                </transition>
              </template>
            </div>
            <div class="transWrap">
              <template v-for="(word, i) in pairTransList">
                <transition leave-active-class="animate__animated animate__faster animate__fadeOut" :key="i">
                  <div v-show="word.show" class="trans block" ref="pairTrans" @click="pairOptionChoosed('trans', word, i)">{{word.trans}}</div>
                </transition>
              </template>
            </div>
          </div>
          <div v-show="pairList.length === choosedNum" class="continueBtn" @click="$emit('hideWindow')">{{$t('course.FINISH')}}</div>
        </div>
      </div>
    </div>

    <el-drawer
      custom-class="favWordListWindow"
      :visible.sync="favWordListWindowShow"
      size="100%"
      append-to-body
      >
      <word-fav-list :favWords="favWords" @hideWindow="favWordListWindowShow = false"></word-fav-list>
    </el-drawer>
  </div>
</template>

<script>
  import Vuex from 'vuex'
  import { Icon } from '@iconify/vue2'
  import constants from '@/utils/constants'
  import { playAnimAudio } from '@/utils/commonUtil'
  import { getWordTransAndExplain, setFavWordResult } from '@/api/ebook'
  import WordFavList from '@/components/ebook/WordFavList.vue'
  import 'animate.css'

  export default {
    props: ['favWordList', 'ebookID'],
    components: {
      'word-fav-list': WordFavList,
      Icon
    },
    data() {
      return {
        mode: 'shorthand',  // shorthand transSelect transPair
        favWords: [],
        choosedWords: [],
        choosedNum: 5,
        activeWordCard: 1,
        wrongNum: 0,
        transShow: false,
        animStatus: true,
        cardAnimEnterClass: 'fadeInRight',
        cardAnimLeaveClass: 'fadeOutLeft',
        transOptions: [],
        pairList: [],
        pairWordList: [],
        pairTransList: [],
        choosedPairDom: null,
        choosedPairOption: null,
        favWordListWindowShow: false
      }
    },
    computed: {
      ...Vuex.mapGetters(['userInfo', 'userLanguage', 'learnLanguage']),
      currentWord() {
        return this.choosedWords[this.activeWordCard - 1]
      },
      headerTitle() {
        if (this.mode === 'transSelect') {
          return this.$t('ebook.ebook_word_practice_type_2')
        } else if (this.mode === 'transPair') {
          return this.$t('ebook.ebook_word_practice_type_3')
        } else {
          return this.$t('ebook.ebook_word_practice_type_1')
        }
      }
    },
    watch: {
      activeWordCard() {
        if (this.mode === 'transSelect') {
          this.wrongNum = 0
          this.initTransOptions()
        }
      },
      animStatus(val) {
        if (!val) {
          this.transShow = false
        }
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      async init() {
        let loadingInstance = this.$loading()

        let favWords = await Promise.all(this.favWordList.map(async (wordData) => {
          let { favID, sent, word } = wordData
          let sentID = favID.slice(6, 9)
          let trunkID = favID.slice(9, 12)
          let wordID = favID.slice(12, 15)
          let { wordTrans } = await getWordTransAndExplain(this.userLanguage, this.learnLanguage, this.ebookID, sentID, trunkID, wordID, sent, word)
          wordData.trans = wordTrans
          return wordData
        }))
        favWords.sort((a, b) => b.fav_time - a.fav_time)
        this.favWords = favWords
        
        // 抽取5个单词
        let favWordsCopy = JSON.parse(JSON.stringify(favWords))
        favWordsCopy.sort((a, b) => {
          // 1. 按照是否未练过排序
          if (a.last_study_time === 0 && b.last_study_time !== 0) return -1
          if (b.last_study_time === 0 && a.last_study_time !== 0) return 1

          // 2. 对于练过的，先排错的，再排对的
          if (!a.last_result && b.last_result) return -1
          if (a.last_result && !b.last_result) return 1
          
          // 3. 相同类别按 last_study_time 升序排序
          return a.last_study_time - b.last_study_time
        })
        this.choosedWords = favWordsCopy.slice(0, this.choosedNum)

        this.$nextTick(() => {
          loadingInstance.close()
        })
      },
      playAudio(wordData) {
        let { word } = wordData
        let encodeWord = encodeURI(encodeURI(word))
        let audioUrl = constants.ebookMediaUrl.replace('LANG', this.learnLanguage) + `read-${this.learnLanguage}-${this.ebookID}-word-${encodeWord}.mp3`
        let audioIcon = this.$refs.audio

        playAnimAudio(audioUrl, audioIcon)
      },
      changeWordIndex(num) {
        if (num < 0) {
          if (this.activeWordCard > 1) {
            this.cardAnimEnterClass = 'fadeInLeft'
            this.cardAnimLeaveClass = 'fadeOutRight'

            this.animStatus = false
            setTimeout(() => {
              this.activeWordCard += num
              this.animStatus = true
            }, 250)
          }
        } else {
          if (this.activeWordCard < this.choosedNum) {
            this.cardAnimEnterClass = 'fadeInRight'
            this.cardAnimLeaveClass = 'fadeOutLeft'

            this.animStatus = false
            setTimeout(() => {
              this.activeWordCard += num
              this.animStatus = true
            }, 250)
          } else {
            if (this.mode === 'transSelect') {
              this.animStatus = false
              setTimeout(() => {
                this.mode = 'transPair'
                this.initPairOptions()
              }, 250)
            }
          }
        }
      },
      changeMode(mode) {
        this.mode = mode
        this.activeWordCard = 1
      },
      initTransOptions() {
        let { favID } = this.currentWord
        let excludeCurrentWordArr = this.choosedWords.filter(word => word.favID !== favID)
        let options = excludeCurrentWordArr.sort(() => Math.random() - 0.5).slice(0, 3)
        options.push(this.currentWord)
        options.sort(() => Math.random() - 0.5)
        this.transOptions = options
      },
      initPairOptions() {
        let wordList = this.choosedWords.map(word => ({ favID: word.favID, word: word.word, show: true, wrongNum: 0 }))
        let wordTransList = this.choosedWords.map(word => ({ favID: word.favID, trans: word.trans, show: true, wrongNum: 0 }))
        this.pairWordList = wordList.sort(() => Math.random() - 0.5)
        this.pairTransList = wordTransList.sort(() => Math.random() - 0.5)
      },
      optionChoosed(word, index) {
        let currentDom = this.$refs.op[index]
        let favID = word.favID

        if (favID === this.currentWord.favID) {
          currentDom.classList.add('right')
          setTimeout(() => {
            currentDom.classList.remove('right')
            if (this.userInfo) {
              let result = this.wrongNum > 1 ? false : true
              let uid = this.userInfo.UID
              setFavWordResult(uid, this.learnLanguage, this.ebookID, favID, result)
            }
            setTimeout(() => {
              this.changeWordIndex(1)
            }, 300);
          }, 500)
        } else {
          this.wrongNum ++
          currentDom.classList.add('wrong')
          setTimeout(() => {
            currentDom.classList.remove('wrong')
          }, 500)
        }
      },
      pairOptionChoosed(type, option, index) {
        let currentDom = type === 'word' ? this.$refs.pairWord[index] :  this.$refs.pairTrans[index]
        if (!currentDom.classList.contains('selected')) {
          if (this.choosedPairOption) {
            if (this.choosedPairOption.favID === option.favID) {
              this.choosedPairDom.classList.remove('selected')
              this.choosedPairDom.classList.add('right')
              currentDom.classList.add('right')

              setTimeout(() => {
                let pairData = Object.assign({}, this.choosedPairOption, option)
                pairData.show = false
                this.pairList.push(pairData)

                this.choosedPairDom.classList.remove('right')
                currentDom.classList.remove('right')
                this.choosedPairOption.show = false
                option.show = false
                this.choosedPairDom = null
                this.choosedPairOption = null

                setTimeout(() => {
                  pairData.show = true
                  if (this.userInfo) {
                    let uid = this.userInfo.UID
                    let word = this.pairWordList.find(word => word.favID === option.favID)
                    let result = word.wrongNum > 1 ? false : true
                    setFavWordResult(uid, this.learnLanguage, this.ebookID, option.favID, result)
                  }
                }, 500)
              }, 500)
            } else {
              this.choosedPairDom.classList.remove('selected')
              this.choosedPairDom.classList.add('wrong')
              currentDom.classList.add('wrong')

              option.wrongNum ++
              this.choosedPairOption.wrongNum ++

              setTimeout(() => {
                this.choosedPairDom.classList.remove('wrong')
                currentDom.classList.remove('wrong')
                this.choosedPairDom = null
                this.choosedPairOption = null
              }, 500)
            }
          } else {
            currentDom.classList.add('selected')
            this.choosedPairOption = option
            this.choosedPairDom = currentDom
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .wordShorthand {
    width: 100%;
    height: 100vh;
    background: #F3F4F8;
    .header {
      width: 100%;
      height: 80px;
      background: #FFFFFF;
      box-shadow: 0 0 2px #8398AE;
      .headerWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        padding: 0 20px;
        width: 100%;
        max-width: 1000px;
        height: 100%;
        box-sizing: border-box;
        .headerBtn {
          display: flex;
          &:hover {
            cursor: pointer;
            opacity: 0.7;
          }
          .headerIcon {
            width: 32px;
            height: 32px;
            color: #333333;
          }
        }
        .headerTitle {
          font-weight: 500;
          font-size: 24px;
          color: #333333;
        }
      }
    }
    .content {
      height: calc(100% - 81px);
      overflow: auto;
      .contentWrap {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .word {
          font-size: 36px;
          font-weight: 500;
          color: #333333;
        }
        .wordIndex {
          font-size: 16px;
          color: #333333;
        }
        .continueBtn {
          position: fixed;
          bottom: 5vh;
          left: 50%;
          transform: translateX(-50%);
          width: 80%;
          max-width: 300px;
          padding: 0 20px;
          height: 50px;
          line-height: 50px;
          border-radius: 50px;
          font-weight: 500;
          font-size: 14px;
          color: #FFFFFF;
          box-sizing: border-box;
          display: inline-block;
          text-align: center;
          transition: all 0.1s;
          background: #4388FF;
          box-shadow: 0 2px 4px 0 rgba(#4388FF, 0.57);
          &:hover {
            background: #2D71E7;
            cursor: pointer;
          }
        }
        .wordCardWrap {
          width: 100%;
          max-width: 450px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 50px;
          .wordCard {
            width: 90%;
            min-height: 400px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            gap: 50px;
            padding: 80px 20px 50px;
            border-radius: 16px;
            background: #FFFFFF;
            box-shadow: 0 2px 4px 0 #0000001A;
            box-sizing: border-box;
            .audioIcon {
              position: absolute;
              top: 20px;
              right: 20px;
              width: 40px;
              height: 40px;
              background-size: 120px;
              background-position: -80px 4px;
              background-repeat: no-repeat;
              background-image: url('~@/assets/images/ebook/audioBlue.png');
              &:hover {
                cursor: pointer;
                opacity: 0.7;
              }
              &.active {
                animation: audioBlue 0.6s infinite steps(3);
                @keyframes audioBlue {
                  0% {
                    background-position: 0 4px;
                  }
                  100% {
                    background-position: -120px 4px;
                  }
                }
              }
            }
            .transWrap {
              width: 100%;
              .trans {
                font-size: 24px;
                font-weight: 500;
                color: #333333;
                opacity: 0.55;
                text-align: center;
              }
              .divider {
                margin-top: 30px;
                width: 100%;
                height: 1px;
                background: linear-gradient(
                  to left,
                  transparent 0%,
                  transparent 50%,
                  #8398AE 50%,
                  #8398AE 100%
                );
                background-size: 16px 1px;
                background-repeat: repeat-x;
              }
            }
            .btnWrap {
              min-width: 30%;
              display: flex;
              align-items: center;
              justify-content: center;
              .transBtn {
                width: 100%;
                padding: 10px 20px;
                font-weight: 500;
                font-size: 18px;
                border-radius: 14px;
                border: 2px solid #4388FF;
                color: #4388FF;
                background: #4388ff38;
                text-align: center;
                box-sizing: border-box;
                transition: all 0.1s;
                &:hover {
                  cursor: pointer;
                  background: #2d71e75b;
                }
              }
            }
          }
          .wordIndexWrap {
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .changeIndexBtn {
              display: flex;
              &:hover {
                cursor: pointer;
                opacity: 0.7;
              }
              .arrowIcon {
                width: 32px;
                height: 32px;
                color: #4388FF;
              }
            }
          }
        }
        .transSelectWrap {
          width: 100%;
          max-width: 450px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .questionWrap {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .transOptions {
              margin-top: 50px;
              width: 90%;
              display: flex;
              flex-direction: column;
              gap: 20px;
              .transOption {
                width: 100%;
                height: 50px;
                line-height: 50px;
                border-radius: 50px;
                background: #FFFFFF;
                font-size: 18px;
                color: #333333;
                box-shadow: 0 1px 3px 0 #0000000D;
                text-align: center;
                transition: all 0.3s;
                &:hover {
                  cursor: pointer;
                  background: #DCE4F2;
                }
                &.right {
                  color: #FFFFFF;
                  background: #2BC58A;
                }
                &.wrong {
                  color: #FFFFFF;
                  background: #EE5151;
                }
              }
            }
          }
          .wordIndex {
            margin-top: 80px;
          }
        }
        .transPairWrap {
          width: 90%;
          max-width: 600px;
          .block {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 80px;
            font-size: 18px;
            border-radius: 10px;
            background: #FFFFFF;
            box-shadow: 0 2px 4px 0 #0000001A;
            text-align: center;
            transition: all 0.3s;
            &.pair {
              margin-bottom: 20px;
              flex-direction: column;
              gap: 5px;
              &:nth-child(5) {
                margin-bottom: 0;
              }
              .word {
                font-size: 20px;
              }
            }
            &:hover {
              cursor: pointer;
              background: #DCE4F2;
            }
            &.right {
              color: #FFFFFF;
              background: #2BC58A;
              .word {
                color: #FFFFFF;
              }
            }
            &.wrong {
              color: #FFFFFF;
              background: #EE5151;
              animation: eyeActive 1s linear;
  
              @keyframes eyeActive {
                10% {
                  transform: translateX(8px);
                }
                20% {
                  transform: translateX(-6px);
                }
                30% {
                  transform: translateX(5px);
                }
                40% {
                  transform: translateX(-5px);
                }
                50%, 100% {
                  transform: translateX(0px);
                }
              }
            }
            &.selected {
              color: #FFFFFF;
              background: #4388FF;
            }
            &.hidden {
              animation: blockHidden 1s linear;
              @keyframes blockHidden {
                from {
                  opacity: 1;
                }
                to {
                  opacity: 0;
                  display: none;
                }
              }
            }
          }
          .bottomWrap {
            display: flex;
            gap: 20px;
            .wordsWrap, .transWrap {
              width: 50%;
              display: flex;
              flex-direction: column;
              gap: 20px;
              transition: all 0.3s;
            }
          }
        }
      }
    }
  }

  ::v-deep .el-drawer__header {
    display: none;
  }
</style>